import './styling/main.less';
import Vue from 'vue';
import './core/errorHandling';
import { router as customRouter } from './core/spa/router'; // Custom naming https://stackoverflow.com/a/56374668/2664445
import './core/config';
import './project/config';
import useHeaderNavigation from '@/project/layout/page-header/useHeaderNavigation';
import App from './App.vue';
const vueDebugEnabled = location.search.includes('$enable-vue-devtools$=true');
if (vueDebugEnabled) {
    Vue.config.devtools = true;
}

const { setNavigation } = useHeaderNavigation();
setNavigation();

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router: customRouter,
    render: h => h(App)
});
