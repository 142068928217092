import Vue from 'vue';
import constants from '@/project/config/constants';

import GmapVue from 'gmap-vue';

Vue.use(GmapVue, {
    load: {
        key: constants.GoogleApiKey,
        library: 'places'
    }
});
