import Vue from 'vue';
import stickyFill from 'stickyfilljs';

Vue.directive('sticky', {
    inserted: function(el) {
        el.classList.add('sticky-fill');
        stickyFill.add(el);
    },
    unbind: function(el) {
        stickyFill.remove(el);
        el.classList.remove('sticky-fill');
    }
});
