import Vue from 'vue';
import translateFilter from '@/core/translation/translate.filter';
import Api from '@/project/http/api';
import {
    validateDhlCustomerNo,
    validateName,
    validateStringHasChars,
    validateStringHasNumber
} from '@/project/shared/string.util';
import serverContext from '@/core/serverContext.service';
import veeValidate from 'vee-validate';
// Vee-validate validation-rule to label. The label can include a {0} which will expanded with the field (use data-vv-as on the input to provide a translated field-name)
// Look in node_modules\vee-validate\dist\locale\da.js for inspiration

const labelMappings = {
    required: 'validation.Required',
    email: 'validation.Email',
    min: 'validation.Min',
    max: 'validation.Max',
    is: 'validation.Is',
    regex: 'validation.Regex',
    integer: 'validation.Integer',
    min_value: 'validation.Integer', // might need a better text later
    name: 'validation.Custom.Name.NoIllegalCharacters',
    streetnumber: 'validation.Custom.Street.HasNumber',
    streetname: 'validation.Custom.Street.HasChars',
    phonenumberwithspace: 'validation.Custom.InvoiceAddressPhone.Length'
};

loadVeeValidate();

async function loadVeeValidate() {
    // Temp. outcommented. Need to find good solution for hard-reload.
    // const veeValidate = await import(/* webpackChunkName: 'vee-validate' */'vee-validate');

    const validateConfig = {
        locale: 'all', // Arbitrary name - because we use labels for messages.
        errorBagName: 'vvErrors',
        fieldsBagName: 'vvFields'
    };
    Vue.use(veeValidate, validateConfig);

    const dictionary: any = {
        messages: {
            // Fallback if developer did not create message above in labelMappings
            _default: (field) => `Manglende VeeValidate validation type. Felt: ${field}`
        }
    };
    Object.keys(labelMappings).forEach((labelMapping) => {
        dictionary.messages[labelMapping] = (field) => translateFilter(labelMappings[labelMapping], field);
    });

    // Field specific validation rule overrides
    const birthDay = {
        decimal: translateFilter('validation.Custom.Birthday'),
        min_value: translateFilter('validation.Custom.Birthday'),
        max_value: translateFilter('validation.Custom.Birthday'),
        min_age: translateFilter('login.CreateUser.Error.BirthdayMinAge')
    };
    const phone = {
        length: (field, len) => translateFilter('validation.Custom.InvoiceAddressPhone.Length', field, len),
        integer: (field) => translateFilter('validation.Custom.InvoiceAddressPhone.Integer', field),
        // For now, this is ok, might need more specific description than reuse
        min_value: (field, len) => translateFilter('validation.Custom.InvoiceAddressPhone.Integer', field, len)
    };
    dictionary.custom = {
        password: {
            min: (field, num) => translateFilter('validation.Custom.Password.Min', field, num)
        },
        passwordRepeat: {
            is: translateFilter('validation.Custom.Password.Repeat')
        },
        invoiceAddressPhone: phone,
        phone,
        dateOfBirthDay: birthDay,
        dateOfBirthMonth: birthDay,
        dateOfBirthYear: birthDay,
        termsAccept: {
            required: translateFilter('validation.Custom.TermsAccept')
        },
        remindMeEmailsAccept: {
            required: translateFilter('validation.Custom.RemindMeEmailsAccept')
        }
    };

    veeValidate.Validator.localize('all', dictionary);

    veeValidate.Validator.extend('name', {
        validate: (value) => {
            return validateName(value);
        }
    });

    // Custom validator used in BirthDayInput.vue
    veeValidate.Validator.extend('min_age', {
        paramNames: ['minAge', 'birthday'],
        validate: (value, args) => {
            const argum = args as { minAge: number; birthday: number };
            const now = new Date();
            const minAge = argum && argum.minAge;
            const birthday = argum && argum.birthday;
            now.setHours(24);
            now.setMinutes(0, 0, 0);
            now.setFullYear(now.getFullYear() - minAge);
            const diff = now.getTime() - new Date(birthday).getTime();
            const valid = !(diff < 0);
            return valid;
        }
    });

    /*
     * Custom validator used for street addresses that has letters
     * Must match both
     * 123 streetname
     * streetname 123
     * streetname 123 apratment 2b etc
     */
    veeValidate.Validator.extend('streetname', {
        validate: (value) => {
            return validateStringHasChars(value);
        }
    });

    /*
     * Custom validator used for street addresses that has number
     * Must match both
     * 123 streetname
     * streetname 123
     * streetname 123 apratment 2b etc
     */
    veeValidate.Validator.extend('streetnumber', {
        validate: (value) => {
            return validateStringHasNumber(value);
        }
    });

    veeValidate.Validator.extend('postalcodefromcountry', {
        getMessage(field: string, args: any[]): string {
            let format = '8000';
            const selectedCountry = Array.isArray(args) && args.length && args[0];
            switch (selectedCountry) {
            // Postal Codes are easy, just like 4 or 5 digits:

                // 4 digits
                case 'BE':
                    format = '1041';
                    break;
                case 'DK':
                    format = '8200';
                    break;
                case 'NO':
                    format = '0010';
                    break;
                case 'CH':
                    format = '3436';
                    break;
                case 'AT':
                    format = '1901';
                    break;

                // 5 digits
                case 'FI':
                    format = '00100';
                    break;
                case 'FR':
                    format = '75008';
                    break;
                case 'DE':
                    format = '10115';
                    break;

                case 'SE':
                // Then Sweden is a little special
                    format = '10x xx';
                    break;
                case 'NL':
                // Holland too...
                    format = '1000 AP';
                    break;
                case 'FO':
                case 'IS':
                // Few are unknown
                    format = ''; // Unknown
                    break;
                case 'IE':
                // Ireland is odd...
                    format = 'A94 X7Y0';
                    break;
                case 'GB':
                // England, Brexit, dont say a thing...
                    format = 'SW11 7US';
                    break;
            }
            return translateFilter('validation.Custom.PostalCode.ByCountry', field, format);
        },
        validate: (value, args) => {
            const selectedCountry = Array.isArray(args) && args.length && args[0];
            const selectedMarket = serverContext.availableCountries.find((c) => c.countryCode === selectedCountry);
            const postalPattern = selectedMarket ? selectedMarket.postalCodePattern : null;
            if (postalPattern) {
                const regex = new RegExp(postalPattern, 'ig');
                const regmatch = value.trim().match(regex);
                const match = regmatch && regmatch.length > 0;
                return match || false;
            }
            // if we dont have any pattern, this validation will just pass true.
            return true;
        }
    });

    veeValidate.Validator.extend('email_exist', {
        getMessage(field: string, params: any[], data: any): string {
            return translateFilter(data.message, data.email);
        },
        validate: async(value) => {
            const result = await Api.user.doesAccountExistByEmail({ email: value }).then((response) => {
                return {
                    valid: !response.exists,
                    data: {
                        email: value,
                        message: 'login.CreateUser.Error.UserExists'
                    }
                };
            });
            return result;
        }
    });

    veeValidate.Validator.extend('phonenumberwithspace', {
        getMessage(field: string, args: any[], data: any): string {
            const { isRegexMatch, countrySpecificMinLength, countrySpecificMaxLength } = data;
            if (!isRegexMatch) return translateFilter('validation.Custom.InvoiceAddressPhone.Integer');
            return translateFilter(
                countrySpecificMinLength === countrySpecificMaxLength
                    ? 'validation.Custom.InvoiceAddressPhone.CountrySpecificFixedLength'
                    : 'validation.Custom.InvoiceAddressPhone.CountrySpecificLength',
                field,
                countrySpecificMinLength.toString(),
                countrySpecificMaxLength.toString()
            );
        },
        validate: (value, args) => {
            const phonePrefix = Array.isArray(args) && args.length && args[0];
            const selectedMarket = serverContext.availableCountries.find((c) => c.phonePrefix === phonePrefix);
            const regex = new RegExp(/^[0-9 ]+$/); // Space and Numbers
            const regmatch = value.match(regex);
            const match = regmatch && regmatch.length > 0;
            const isRegexMatch = match || false;
            const valueWithoutSpaces = value.replace(/ /g, '');
            // Country specific validation
            const countrySpecificMinLength = selectedMarket?.phoneMinLength || 3;
            const countrySpecificMaxLength = selectedMarket?.phoneMaxLength || 20;
            // See https://en.wikipedia.org/wiki/Telephone_numbers_in_Europe
            const countrySpecificMinValid = valueWithoutSpaces.toString().length >= countrySpecificMinLength;
            const countrySpecificMaxValid = valueWithoutSpaces.toString().length <= countrySpecificMaxLength;
            const isValid = isRegexMatch && countrySpecificMinValid && countrySpecificMaxValid;
            return {
                valid: isValid,
                data: {
                    value: valueWithoutSpaces,
                    isRegexMatch,
                    countrySpecificMinValid,
                    countrySpecificMinLength,
                    countrySpecificMaxValid,
                    countrySpecificMaxLength,
                    phonePrefix
                }
            };
        }
    });

    veeValidate.Validator.extend('dhl_customer_number', {
        getMessage(field: string): string {
            return translateFilter('validation.Custom.DhlCustomerNo', field);
        },
        validate: (value) => {
            return validateDhlCustomerNo(value);
        }
    });
}
