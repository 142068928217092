<template>
    <ul class="ml-auto flex items-center">
        <li v-show="!isSearchPage"
            class="search-icon mr-12 md:hidden"
            role="search">
            <button
                v-prohibit-zoom
                class="cursor-pointer text-brown-80"
                :aria-label="labelToggleSearch"
                @click="openOverlay">
                <cIcon name="search"
                       class="text-brown-80"
                       height="20"/>
            </button>
        </li>
        <li class="hidden relative mr-12 md:block">
            <RouterLinkConditional
                v-if="headerNavigation?.findStore?.url && headerNavigation.findStore.name"
                v-prohibit-zoom
                :to="headerNavigation.findStore.url"
                class="absolute inset-0"
                :title="headerNavigation.findStore.name"/>
            <cIcon name="shop"
                   class="text-brown-80"
                   height="20px"/>
        </li>
        <li class="hidden relative mr-12 md:block">
            <RouterLinkConditional
                v-if="headerNavigation?.myPage?.url && headerNavigation.myPage.name"
                v-prohibit-zoom
                :to="headerNavigation.myPage.url"
                class="absolute inset-0"
                :title="headerNavigation.myPage.name"/>
            <cIcon name="user"
                   class="text-brown-80"
                   height="20px"/>
        </li>
        <li class="hidden relative mr-12 md:block">
            <RouterLinkConditional
                v-if="headerNavigation?.favorites?.url"
                v-prohibit-zoom
                :to="headerNavigation.favorites.url"
                class="absolute inset-0"
                :title="headerNavigation.favorites.name"/>
            <cIcon name="heart"
                   class="text-brown-80"
                   height="17.5px"/>
        </li>
        <li v-if="hasCheckout"
            class="flex"
            :class="{'divider-bar': !isSearchPage}">
            <button
                v-prohibit-zoom
                class="cursor-pointer text-brown-80 mt-2"
                :aria-label="labelToggleBasketAria"
                @click="toggleBasket">
                <span class="sr-only">{{ labelToggleBasket }}</span>
                <MiniBasket :animate="true"/>
            </button>
        </li>
    </ul>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import MiniBasket from '@/project/checkout/MiniBasket.vue';
import useHeaderNavigation from './useHeaderNavigation';
import serverContextService from '@/core/serverContext.service';
import translateFilter from '@/core/translation/translate.filter';
import basketStore from '@/store/basket.store';
import useSearch from '@/project/search/useSearch';

const props = defineProps<{
    isSearchActiveInternally: boolean;
}>();

const { headerNavigation } = useHeaderNavigation();
const { openOverlay, isSearchPage } = useSearch();

const toggleBasket = () => {
    basketStore.toggleBasketActiveState();
};

const hasCheckout = computed(() => {
    return serverContextService.checkoutContext.hasCheckout || false;
});

const basketCount = computed(() => {
    if (hasCheckout.value && basketStore.basket && basketStore.itemCount !== null) {
        return basketStore.itemCount;
    }
    return null;
});

const basketActive = computed(() => {
    return basketStore.basketActive;
});

const labelToggleSearch = computed(() => {
    return translateFilter(props.isSearchActiveInternally ? 'search.Aria.Close' : 'search.Aria.Open');
});

const labelToggleBasket = computed(() => {
    return toggleBasketLabelHelperFunction();
});

const labelToggleBasketAria = computed(() => {
    return toggleBasketLabelHelperFunction(true);
});

const toggleBasketLabelHelperFunction = (includeBasketCount: boolean = false) => {
    return translateFilter(basketActive.value ? 'checkout.Basket.HideBasket' : 'checkout.Basket.ShowBasket') +
        includeBasketCount
        ? ` (${basketCount.value || 0})`
        : '';
};

</script>

<style lang="less">
.search-icon {
    width: 2.0rem;
    height: 1.8rem;
}

.divider-bar {
    &::before {
        @apply inline-block bg-brown-100 h-24 w-1;
        margin-right: 1.2rem;
        content: '';
        opacity: 0.2;
    }
}

@screen md {
    .divider-bar {
        @apply mr-0;
        &::before {
            display:none;
        }
    }
}
</style>