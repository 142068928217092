import Vue from 'vue';
import SvgIcon from 'vue-svgicon'; // We are using vue-svgicon to generate icons as components  - https://github.com/MMF-FE/vue-svgicon

import cIcon from './c-icon.vue';
// eslint-disable-next-line
Vue.component('cIcon', cIcon);

// Default tag name is 'svgicon'
Vue.use(SvgIcon, {
    tagName: 'd-icon',
    classPrefix: 'c-icon'
});
const complianceIconOekotex = () => import('./compliance-icon-oekotex.vue');
const complianceIconOekotexGreen = () => import('./compliance-icon-oekotex-green.vue');
Vue.component('ComplianceIconOekotex', complianceIconOekotex);
Vue.component('ComplianceIconOekotexGreen', complianceIconOekotexGreen);
