<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { File, Media, v4 } from '@/types/serverContract';
import find from 'lodash-es/find';
import { getFirstVariantSalesColor, productVariesOnColor, selectedVariant } from '@/project/product/productHelper.utils';
import { BasketLineWithNewProduct } from '../http/api';

@Component
export default class LineItemWrapper extends Vue {
    @Prop({
        type: Object,
        required: true
    }) lineItem!: BasketLineWithNewProduct;

    get getImage(): File | Media | Record<string, unknown> {
        const variant = this.getVariant;
        const images = variant ? variant.media : selectedVariant(this.lineItem.product.variants, this.lineItem.product.mostRelevantVariant).media;
        return images && images.length ? images[0] : {};
    }

    get getVariant() : v4.Products.VariantSimple {
        const variant = find(this.lineItem.product.variants, (variant) => variant.sku === this.lineItem.sku) as v4.Products.VariantSimple;
        return variant;
    }

    get isVariantOnColor(): boolean {
        return productVariesOnColor(this.lineItem.product);
    }

    get variantUrl() : string {
        return this.lineItem.product.url + ((this.lineItem.sku !== this.lineItem.product.variants[0].sku) ? ('?variant=' + this.lineItem.sku) : '');
    }

    get getFirstVariantSalesColorCode(): string | null {
        return getFirstVariantSalesColor(this.getVariant)?.code ?? null;
    }

    render() {
        return this.$scopedSlots.default!({
            getImage: this.getImage,
            variantUrl: this.variantUrl,
            variantColorCode: this.getFirstVariantSalesColorCode,
            isVariantOnColor: this.isVariantOnColor
        });
    }
}
</script>
