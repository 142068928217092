<template>
    <div class="page"
         :class="{'is-changing-page': isChangingPage}">
        <component :is="resolvedPage"
                   v-if="resolvedPage"
                   :content="content"
                   :alias="alias"/>
    </div>
</template>

<script lang="ts">
/*
The page is not replaced until animation out has been active for a while -
so it won't show old page if the page is received very fast from server.
Also - no matter how fast a new page loads, we will give the animation-out and animation-in all the time they need.
 */
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { pageResolver } from '@/core/spa/componentResolver.service';
import spaStore from '@/core/spa/store/spa.store';
import { JsonContent } from '@/types/serverContract';
import { SpaPageRenderedEventKey } from '@/core/spa/router';
import bus from '@/core/bus';

@Component
export default class PageRender extends Vue {
    pageDataState: 'done' | 'awaitingNewPage' | 'newPageReceived' = 'done';
    timerState: 'done' | 'awaitingAnimationOut' | 'awaitingMinimumAnimationTime' = 'done';
    localJsonContent : JsonContent | null = null;

    mounted() {
        this.renderNewPage();
    }

    get resolvedPage(): any {
        return this.alias ? pageResolver.resolve(this.alias) : null;
    }

    get alias(): any | null {
        return this.localJsonContent ? this.localJsonContent.alias : null;
    }

    get content(): any | null {
        return this.localJsonContent ? this.localJsonContent.content : null;
    }

    get isFetchingSpaPage() {
        return spaStore.isLoadingSpaPage;
    }

    get spaStoreJsonContent() {
        return spaStore.jsonContent;
    }

    get isChangingPage(): boolean {
        return this.timerState !== 'done' || this.pageDataState !== 'done';
    }

    @Watch('isFetchingSpaPage')
    onIsFetchingSpaPageChange(isFetching: boolean) {
        if (isFetching) {
            this.pageDataState = 'awaitingNewPage';
            this.timerState = 'awaitingAnimationOut';

            setTimeout(() => this.animationOutFired(), 200); // Delay before we replace page (if new page ready).
            setTimeout(() => this.minimumAnimationTimeFired(), 300); // Animation out + animation in time.
        }
    }

    animationOutFired() {
        this.timerState = 'awaitingMinimumAnimationTime';
        if (this.pageDataState === 'newPageReceived') {
            this.renderNewPage();
        }
    }

    @Watch('spaStoreJsonContent')
    onSpaStoreJsonContentChange() {
        this.pageDataState = 'newPageReceived';
        if (this.timerState === 'awaitingMinimumAnimationTime' || this.timerState === 'done') {
            this.renderNewPage();
        }
    }

    renderNewPage() {
        this.localJsonContent = this.spaStoreJsonContent;
        this.$nextTick(() => {
            this.setPageReady();
        }, 0);
    }

    setPageReady() {
        setTimeout(() => {
            // Page should be rendered.
            this.pageDataState = 'done';
        }, 0);
        // Fire event for router (scrolling).
        bus.emit(SpaPageRenderedEventKey);
    }

    minimumAnimationTimeFired() {
        this.timerState = 'done';
    }
}
</script>

<style lang="less" scoped>
    @import (less, reference) '../../../node_modules/animate.css/animate.css';

    .page {
        .animated();
        .fadeIn();
        animation-duration: 250ms;

        &.is-changing-page {
            .fadeOut();
        }
    }
</style>
