<template>
    <div>
        <div class="flex items-center bg-sand-20 py-16 px-20 z-1 sticky top-0 xl:static xl:block">
            <button class="transform mr-16 lg:hidden"
                    :aria-label="$translate('Search.Overlay.CloseShort')"
                    @click="closeOverlay">
                <cIcon name="chevron"
                       width="22"
                       height="22"
                       class=""/>
            </button>
            <div class="mr-auto w-full">
                <SearchInput/> 
            </div>
        </div>
        <SearchSuggestionsOverlay class="z-3"/>
    </div>
</template>

<script setup lang="ts">
import useSearch from '@/project/search/useSearch';
import SearchInput from '../SearchInput.vue';
import SearchSuggestionsOverlay from './SearchSuggestionsOverlay.vue';

const { closeOverlay } = useSearch();

</script>
