<template>
    <PortalOverlay
        :wrapper-class="wrapperClass"
        :side="side"
        :show="open"
        :show-close-button="false"
        :close-on-route-change="true"
        @cancelled="closeBasket">
        <Basket @clear-show="closeBasket"/>
    </PortalOverlay>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';
import BreakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';
import serverContext from '@/core/serverContext.service';
import basketStore from '@/store/basket.store';
import Basket from '@/project/checkout/Basket.vue';

const route = useRoute();

const checkoutUrl = computed((): string => serverContext.checkoutContext.checkoutUrl);
const isCheckout = computed(() => checkoutUrl.value === route.path);

const open = computed(() => basketStore.basketActive);
const side = ref<'bottom' | 'right'>('right');
watch(
    [isCheckout, () => BreakpointsState.isBreakpointActive('min-md')],
    ([isCheckout, isMinMd]) => {
        if (isCheckout && !isMinMd) {
            side.value = 'bottom';
        } else {
            side.value = 'right';
        }
    },
    { immediate: true }
);

const wrapperClass = computed(() => {
    const classes = ['c-basket-overlay-wrapper', 'md:max-w-720', 'md:ml-auto'];

    if (isCheckout.value) {
        classes.push('c-basket-overlay-wrapper--checkout');
    }

    return classes.join(' ');
});

const closeBasket = () => {
    basketStore.setBasketActiveState(false);
};
</script>

<style>
.c-basket-overlay-wrapper.c-basket-overlay-wrapper {
    top: 0;
    height: 100vh;
    height: 100dvh;
}

.c-basket-overlay-wrapper--checkout.c-basket-overlay-wrapper--checkout {
    top: 5.6rem;
    height: calc(100vh - 5.6rem);
    height: calc(100dvh - 5.6rem);
}

@screen md {
    .c-basket-overlay-wrapper.c-basket-overlay-wrapper {
        top: 0;
        height: 100vh;
        height: 100dvh;
    }
}
</style>
