import { ref, watch } from 'vue';
import Api from '@/project/http/api';
import productsStore from '@/store/products/products.store';
import { ProductDetailsContentModel, v4 } from '@/types/serverContract';

export type RecommendationsStatus = 'INIT' | 'LOADING' | 'DONE' | 'ERROR';

export const recommendationsId = ref<ProductDetailsContentModel['details']['id']>('');
export const variationSku = ref<ProductDetailsContentModel['details']['variants'][0]['sku']>('');

export const useRecommendations = (productId: typeof recommendationsId, selectedVariantSku?: typeof variationSku) => {
    const recommendations = ref<v4.Products.ProductRecommendationsIdOnly[] | null>(null);
    const recentlyViewed = ref<v4.Products.ProductRecommendationsIdOnly[] | null>(null);
    const status = ref<RecommendationsStatus>('INIT');

    const reset = () => {
        recommendations.value = null;
        recentlyViewed.value = null;
        status.value = 'INIT';
    };

    const onRecommendationInView = (inView: boolean) => {
        if (inView && status.value === 'INIT') {
            fetchRecommendations();
        }
    };

    const fetchRecommendations = async(variantSku?: typeof variationSku) => {
        status.value = 'LOADING';
        try {
            const productIds: string[] = [];
            const data = await Api.recommendations.recommendationsIdOnly(productId.value, variantSku?.value || selectedVariantSku?.value);

            data.forEach((recommendation) => {
                recommendation.products.forEach((product) => {
                    productIds.push(product.id);
                });
            });

            productsStore.ensureLoaded(productIds);

            const filteredData = data.filter((x) => x.products.length > 0);
            recommendations.value = filteredData.filter((x) => x.type !== v4.Products.ProductRecommendationType.RecentlyViewed);
            recentlyViewed.value = filteredData.filter((x) => x.type === v4.Products.ProductRecommendationType.RecentlyViewed);

            status.value = 'DONE';
        } catch (error) {
            status.value = 'ERROR';
        }
    };

    watch(productId, () => {
        reset();
    });

    return {
        recommendations,
        recentlyViewed,
        status,
        onRecommendationInView,
        fetchRecommendations
    };
};
