import { ref, computed } from 'vue';
import { Media, MediaType } from '@/types/serverContract';

// Functionality related to PDP Carousel which is used in different contexts
const shouldPlayVideo = ref(false);
const activeIndex = ref(0);

export const useVideo = (media?: Media[]) => {
    const firstVideoIndex = ref(-1);
    const setVideoIndex = (sortedmedia) => {
        firstVideoIndex.value = sortedmedia?.findIndex(x => x.type === MediaType.Video);
    };

    const hasVideo = computed(() => {
        return media?.some((m) => m.type === MediaType.Video);
    });

    const shouldShowPlayIcon = computed(() => {
        return hasVideo.value && activeIndex.value === 0 && firstVideoIndex.value === 1 && !shouldPlayVideo.value;
    });

    const togglePlayVideo = (newVal?: boolean) => {
        if (typeof newVal === 'boolean') {
            shouldPlayVideo.value = newVal;
            return;
        }
        shouldPlayVideo.value = !shouldPlayVideo.value;
    };

    const goToFirstVideo = () => {
        if (firstVideoIndex.value) {
            shouldPlayVideo.value = true;
            activeIndex.value = firstVideoIndex.value;
        }
    };

    const onSlideChange = (newActiveIndex) => {
        activeIndex.value = newActiveIndex;
        if (activeIndex.value !== firstVideoIndex.value) {
            shouldPlayVideo.value = false;
        }
    };

    const isVideo = (media: Media) => {
        return media.type === MediaType.Video;
    };

    return {
        shouldPlayVideo,
        hasVideo,
        shouldShowPlayIcon,
        activeIndex,
        goToFirstVideo,
        onSlideChange,
        togglePlayVideo,
        isVideo,
        setVideoIndex
    };
};

export default useVideo;
