<template>
    <div v-if="activeSlot !== undefined || error"
         :class="displayBoxAsContents ? 'contents' : ''"> 
        <div v-if="showError"
             class="text-red-600 font-semi text-20 whitespace-pre">
            {{ error }}
        </div>
        <slot v-else
              :name="activeSlot"
              :split-test-variation="activeVariation"/>
    </div>
</template>

<script lang="ts" setup>

/* 
    Inspired by the SplitTest component (without pluralis s). This component can handle all split test cases of a single growthbook test feature.
    You specify the feature key and the variations as slots. The component will then automatically select the active variation and render the corresponding slot.
    You can specify multiple variations for a single slot by separating them with a comma. The component will then render the slot for the active variation.
    There is a special slot called '_rest' that will be rendered if the active variation is not handled by any other slot.
    If the active variation is not available, the fallback variation will be rendered. If no fallback variation is specified, the component will render nothing.
    If the feature is not available, the component will render nothing.
*/

import { computed, ref, useSlots } from 'vue';
import useGrowthbook, { type GrowthBookType } from '@/project/growthbook/useGrowthbook';
import logging from '@/core/logging.service';

const props = withDefaults(defineProps<{
    featureKey: string;
    fallbackVariation?: string;
    noFallback?: boolean;
    displayBoxAsContents?: boolean;
}>(), {
    fallbackVariation: '',
    displayBoxAsContents: true,
    noFallback: false,
});

const REST_SLOT_NAME = '_rest';
const error = ref<string | undefined>('');
const { growthBook, getActiveVariation, isFeatureAvailable, getFeatureVariations } = useGrowthbook();
const variationToSlot = getVariationToSlotMapping(useSlots());
const variationsInSlots = Object.keys(variationToSlot);
const activeSlot = ref<string | null | undefined>();
const activeVariation = ref<string | null>(null);
const hasRestSlot = variationsInSlots.includes(REST_SLOT_NAME);

if (!error.value && validateFallback()) {
    growthBook.then((gb) => {
        setActiveVariation(gb);
    });
}

const setActiveVariation = async(gb: GrowthBookType) => {
    if (!gb || !(await isFeatureAvailable(gb, props.featureKey))) {
        activeSlot.value = variationToSlot[props.fallbackVariation || ''] || null;
        return;
    }

    validateVariationsAndSlots(gb);

    activeVariation.value = await getActiveVariation(props.featureKey);
    activeSlot.value = variationToSlot[activeVariation.value || ''] || (hasRestSlot ? REST_SLOT_NAME : null);
};

async function validateVariationsAndSlots(gb) {
    try {
        const variationSlotNames = variationsInSlots.filter((key) => key !== REST_SLOT_NAME);
        const allVariations = await getFeatureVariations(gb, props.featureKey);

        variationSlotNames.forEach((variation) => {
            if (!allVariations.includes(variation)) {
                throw new Error(`Slot with name '${variation}' is not a valid split test variation. Possible values are: '${allVariations.join(', ')}' and ${REST_SLOT_NAME}`);
            }
        });

        allVariations.forEach((variation) => {
            if (!variationSlotNames.includes(variation) && !hasRestSlot) {
                throw new Error(`Variation '${variation}' is not handled by a slot or the ${REST_SLOT_NAME} slot.`);
            }
        });
    } catch (e) {
        setError(e as string);
    }
}

function getVariationToSlotMapping(slots) {
    try {
        return Object.keys(slots).reduce((acc, name) => {
            const allVariationNames = name.split(',').map((n) => n.trim());
            allVariationNames.forEach((variationName) => {
                if (acc[variationName]) {
                    throw new Error(`Variation with name '${variationName}' is used twice.`);
                }
                acc[variationName] = name;
            });
            return acc;
        }, {});
    } catch (e) {
        setError(e as string);
        return [];
    }
}

function validateFallback() {
    if (!props.noFallback && !props.fallbackVariation) {
        setError('Either specify no-fallback or fallback-variation.');
        return false;
    }

    if (props.fallbackVariation && !variationsInSlots.includes(props.fallbackVariation)) {
        setError(`FallbackVariation '${props.fallbackVariation}' is not represented by a slot!`);
        return false;
    }
    return true;
}

function setError(e: string) {
    error.value = `SplitTest ${props.featureKey}\n${e}`;
    logging.error(error.value);
}

const showError = computed(() => error.value && import.meta.env.NODE_ENV === 'development');


</script>
