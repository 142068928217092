<template>
    <div class="o-container text-center">
        <div :style="setMaxTextWidth"
             class="mx-auto c-text-section-header__wrap">
            <component :is="getHeaderTagType"
                       v-if="content.title"
                       :id="getHeaderAnchorLink">
                <div v-if="content.supplementaryTitle"
                     class="font-script text-40 md:text-55 mb-5 md:mb-10">
                    {{ content.supplementaryTitle }}
                </div>
                <div v-if="content.title"
                     class="uppercase font-brandon font-normal leading-normal text-22 md:text-40">
                    {{ content.title }}
                </div>
            </component>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { TextSectionHeaderBlockModel } from '@/types/serverContract';
import { getHeaderAnchorLink } from '@/project/shared/string.util';

@Component({
    name: 'BlockTextSectionHeader'
})
export default class BlockTextSectionHeader extends Vue {
    @Prop() content!: TextSectionHeaderBlockModel;

    get setMaxTextWidth() : Record<string, string> {
        const styling : any = {};

        if (this.content && this.content.maximumWidth) {
            styling.width = `${this.content.maximumWidth}%`;
        }

        return styling;
    }

    get getHeaderTagType() : string {
        return this.content.headerType || 'h3';
    }

    get getHeaderAnchorLink(): string | undefined {        return getHeaderAnchorLink(this.content);
    }
}
</script>

<style lang="less" scoped>
@import (reference) "../../../styling/0-Settings/index.less";
@media @maxTitleWidthOverwriteMediaQuery {
    .c-text-section-header__wrap {
        @apply max-w-620;
        width: auto !important;
    }
}
</style>
