import Vue from 'vue';

type CurrencyFilterOptions = {
    first?: boolean;
};

function uppercaseFilter(input: string, options?: CurrencyFilterOptions): string {
    if (!input) {
        return '';
    }

    if (options && options.first) {
        return input.charAt(0).toUpperCase() + input.slice(1);
    }

    return input.toUpperCase();
}

// Vue2 mixin. Also works for Vue 2.7 script components
Vue.mixin({
    methods: {
        $uppercase: uppercaseFilter
    }
});

export default uppercaseFilter;
