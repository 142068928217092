<template>
    <portal-overlay :show.sync="show"
                    side="top"
                    :show-blind="false"
                    v-bind="$attrs"
                    v-on="$listeners">
        <div class="h-full w-full bg-white-100">
            <div v-if="show"
                 class="aspect-ratio-3-4 absolute inset-0 z-3 m-auto flex h-full w-full md:w-auto">
                <HorizontalContentSlider
                    :key="selectedVariant.sku"
                    :active-index="activeIndex"
                    :slides="slides"
                    class="relative h-full w-full justify-center"
                    wrapper-class="md:absolute md:inset-0 md:top-24 md:bottom-44"
                    dots-class="absolute inset-x-0 bottom-0 mb-12 z-1"
                    @slide-changed="onSlideChange">
                    <template #default>
                        <ProductDetailCarouselSlide
                            v-for="(media, i) in selectedVariant.sortedMedia"
                            ref="carouselElements"
                            :key="`heroCarouselSlide${i}`"
                            :details="details"
                            :selected-variant="selectedVariant"
                            :enable-favorited-by-badge="false"
                            :media="media"
                            :width-on-screen="100"
                            :height="media.height"
                            :width="media.width"
                            :is-video="isVideo(media)"
                            :index="i"
                            class="w-full"/>
                        <transition
                            mode="out-in"
                            leave-active-class="animated fadeOut u-anim-dur-200"
                            enter-active-class="animated fadeIn u-anim-dur-200">
                            <button
                                v-if="shouldShowPlayIcon"
                                key="PlayVideoButton"
                                aria-label="generic.Open"
                                class="absolute bottom-0 left-0 z-2 mb-16 ml-16"
                                @click="goToFirstVideo">
                                <cIcon name="play-video"
                                       original
                                       class="h-30 w-30 text-brown-80"/>
                            </button>
                        </transition>
                    </template>
                </HorizontalContentSlider>
            </div>
        </div>
    </portal-overlay>
</template>

<script lang="ts" setup>
import { ref, onUnmounted, computed, onMounted } from 'vue';
import { v4 } from '@/types/serverContract';
import bus from '@/core/bus';
import { PDP_IMAGE_FULLSCREEN } from '@/project/product/productHelper.utils';
import HorizontalContentSlider from '@/project/shared/HorizontalContentSlider.vue';
import ProductDetailCarouselSlide from '@/project/product/details/ProductDetailCarouselSlide.vue';
import useVideo from '@/project/product/details/useVideo';

const props = defineProps<{
    details: v4.Products.ProductDetails;
    selectedVariant: v4.Products.ProductDetailsVariant;
    activeIndex: number;
}>();

const { onSlideChange, goToFirstVideo, shouldShowPlayIcon, activeIndex, isVideo, setVideoIndex } = useVideo(
    props.selectedVariant.sortedMedia
);

setVideoIndex(props.selectedVariant.sortedMedia);

const show = ref(false);
const showMediaOverlay = (i) => {
    activeIndex.value = i;
    show.value = true;
};

onMounted(() => {
    bus.on(PDP_IMAGE_FULLSCREEN, showMediaOverlay);
});

const slides = computed(() => {
    return props.selectedVariant?.sortedMedia.map((v, i) => i);
});

onUnmounted(() => {
    bus.off(PDP_IMAGE_FULLSCREEN, showMediaOverlay);
});
</script>

<style lang="less">
.aspect-ratio-3-4 {
    aspect-ratio: 3 / 4;
}
</style>
