<template>
    <div class="o-container">
        <div class="md:-ml-40 lg:-ml-90 flex flex-wrap">
            <div v-for="(box, boxIndex) in content.boxes"
                 :key="boxIndex"
                 class="pb-50"
                 :class="boxClasses">
                <div class="border-t">
                    <div class="text-14 font-brandon mt-20 mb-8">
                        {{ boxIndex+1 }}.
                    </div>
                    <h4 class="text-25 md:text-30 mb-30 md:mb-50 md:pr-20 uppercase">
                        {{ box.content.title }}
                    </h4>
                    <div v-delegate-html-links
                         class="wysiwyg mb-10 c-text-boxes__item-body"
                         v-html="box.content.text"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { TextBoxesBlockModel } from '@/types/serverContract';

@Component({
    name: 'BlockTextBoxes'
})
export default class BlockTextBoxes extends Vue {
    @Prop() content!: TextBoxesBlockModel;
    get boxClasses():string {
        return `w-full md:w-1/2 lg:w-1/${this.content.blocksPerLine} md:pl-40 lg:pl-90`;
    }
}
</script>

<style lang="less">
.c-text-boxes__item-body {
    &.wysiwyg {
        @apply leading-wysiwyg;
        ol, ul {
            padding-left: 0;
            margin-left: 0;
            list-style-position: outside;
            li {
                margin-left: 2rem;
                margin-bottom: 2rem;
                padding-left: 2.3rem;
            }
        }
        > p {
            margin-bottom: 1rem;
        }
    }
}
</style>
