<template>
    <div class="bg-sand-10 max-w-410 w-full rounded-2 shadow-style">
        <SplitTest feature-key="Fsst-search_suggestions_quicklink"
                   default-variation="default"
                   is-fallback>
            <Transition
                mode="out-in"
                leave-active-class="animated fadeOut u-anim-dur-400"
                enter-active-class="animated fadeIn u-anim-dur-400">
                <SearchHistory v-if="shouldShowSearchHistory"
                               :history-searches="historySearches"/>
                <div v-else-if="shouldShowDefaultSuggestions"></div>
                <Suggestions v-else/>
            </Transition>
        </SplitTest>
        <SplitTest feature-key="Fsst-search_suggestions_quicklink"
                   variation="quicklinks"
                   default-variation="default">
            <Transition
                mode="out-in"
                leave-active-class="animated fadeOut u-anim-dur-400"
                enter-active-class="animated fadeIn u-anim-dur-400">
                <SearchHistory v-if="shouldShowSearchHistory"
                               :history-searches="historySearches"/>
                <DefaultSuggestions v-else-if="shouldShowDefaultSuggestions"/>
                <Suggestions v-else/>
            </Transition>
        </SplitTest>
    </div>
</template>

<script setup lang="ts">
import DefaultSuggestions from '@/project/search/search-suggest/DefaultSuggestions.vue';
import Suggestions from '@/project/search/search-suggest/Suggestions.vue';
import SearchHistory from '@/project/search/search-suggest/SearchHistory.vue';
import useSearch from '@/project/search/useSearch';
import { computed } from 'vue';
import SplitTest from '@/project/growthbook/SplitTest.vue';

const { searchTerm, searchActive } = useSearch();

const historySearches = computed(()=> {
    const storedHistorySearches = sessionStorage.getItem('SEARCHHISTORY');
    const searches: string[] = storedHistorySearches ? JSON.parse(storedHistorySearches) : [];

    return searches;
});

const shouldShowSearchHistory = computed(()=> {
    return searchActive.value && historySearches.value?.length && (!searchTerm.value || searchTerm.value.length < 2);
});

const shouldShowDefaultSuggestions = computed(()=> {
    return searchActive.value && ((!historySearches.value?.length && searchTerm.value.length < 2) || (historySearches.value?.length && !searchTerm.value));
});

</script>

<style lang="less" scoped>

/deep/ .wrap {
    @apply px-20 pb-56;
}

@screen md {
    .shadow-style {
        box-shadow: 0px 4px 8px 0px rgba(80, 65, 57, 0.16);
    }
    /deep/ .suggestions-max-height {
        max-height: 50dvh;
        min-height: 6rem;
    }

    /deep/.wrap {
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }
        @apply pb-32;
    }
}

</style>