<template>
    <button class="py-22 px-8 flex items-center border-t border-solid border-sand-40"
            @click="open">
        <span class="text-brown-80 font-brandon-regular leading-16 uppercase text-12">
            {{ accordionTitle }}
        </span>
        <cIcon name="chevron"
               width="22"
               height="22"
               class="transform ml-auto -rotate-90 lg:rotate-180"/>
    </button>
</template>

<script setup lang="ts">
import bus from '@/core/bus';
import { OpenProductSpecificationsKey } from '@/project/config/constants';
import { v4 } from '@/types/serverContract';
import { computed } from 'vue';
import trackingUtils from '@/project/tracking/tracking.utils';

const props = defineProps<{
    information: v4.Products.Information,
}>();

const open = () => {
    bus.emit(OpenProductSpecificationsKey, props.information);
    trackingUtils.navigation.trackAccordionOpen(props.information.trackingId);
};

const accordionTitle = computed(() => {
    return props.information.title;
});

</script>
