import screens from '../../styling/screens';
import { defineBreakpoints } from '@/core/responsive/breakpoints/breakpoints.service';
import map from 'lodash-es/map';

/*
// Lets use tailwind config as breakpoint-src
// PREMISE: tailwind config has 'px' units

  Converts something like this:
    screens: {
        sm: '0',
        md: '640px',
        lg: '960px',
        xl: '1025px',
        print: { raw: 'print' },
    },

   To something like this:
   {
       sm: { min: 0, max: 639 },
       md: { min: 640, max: 959 },
       lg: { min: 960, max: 1024 },
       xl: { min: 1025, max: 999999 },
   }
*/

function removeUnit(width: string): number {
    if (width.endsWith('px')) {
        return Number(width.slice(0, width.indexOf('p')));
    } else {
        return Number(width);
    }
}

const onlyBreakpointsWithPixelValue = Object.fromEntries(Object.entries<string>(screens).filter(([bp]) => bp !== 'print'));
const orderedNormalizedBreakpoints = map(onlyBreakpointsWithPixelValue, (min, bp) => {
    return { name: bp, min: removeUnit(min) };
}).sort((a, b) => a.min - b.min);

const breakpointArrayWithMax = map(orderedNormalizedBreakpoints, (bp, ix) => {
    let max;
    if (ix < orderedNormalizedBreakpoints.length - 1) {
        max = orderedNormalizedBreakpoints[ix + 1].min - 1;
    } else {
        max = 999999;
    }
    return { ...bp, ...{ max } };
});

const finalBreakpoints = breakpointArrayWithMax.reduce((acc, bp) => {
    acc[bp.name] = { min: bp.min, max: bp.max };
    return acc;
}, {});

defineBreakpoints(finalBreakpoints);
