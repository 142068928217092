import Vue from 'vue';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import ResponsiveIconWrap from '@/project/shared/ResponsiveIconWrap.vue';
import BlockRender from '@/project/shared/BlockRender.vue';
import UmbracoText from '@/project/shared/UmbracoText.vue';
import CulturePicker from '@/project/layout/culture/CulturePicker.vue';
import '../spa/pages';
import '../spa/blocks';
import '../product';
import '../shared/form/validation.plugin';
import HorizontalLine from '@/project/shared/horizontalLine.vue';
const TextInputNoValidation = () => import('@/project/shared/form/TextInputNoValidation.vue');
const CheckboxCtrl = () => import('@/project/shared/form/CheckboxCtrl.vue');
const SelectCtrl = () => import('@/project/shared/form/SelectCtrl.vue');
const TextInput = () => import(/* webpackChunkName: 'vee-validate' */'@/project/shared/form/TextInput.vue');
const RadioButtonCtrl = () => import('@/project/shared/form/RadioButtonCtrl.vue');
const PhonePrefixSelect = () => import('@/project/shared/form/PhonePrefixSelect.vue');
const CountrySelect = () => import('@/project/shared/form/CountrySelect.vue');
const BirthDayInput = () => import('@/project/shared/form/BirthDayInput.vue');
const EmailSuggest = () => import('@/project/login/EmailSuggest.vue');
const LoginOverlay = () => import('@/project/login/LoginOverlay.vue');
const CheckboxSwitch = () => import('@/project/shared/form/CheckboxSwitch.vue');

// Global components that we want to use on multiple pages
Vue.component('ResponsiveImage', ResponsiveImage);
Vue.component('ResponsiveIconWrap', ResponsiveIconWrap);
Vue.component('BlockRender', BlockRender);
Vue.component('CheckboxCtrl', CheckboxCtrl);
Vue.component('SelectCtrl', SelectCtrl);
Vue.component('TextInput', TextInput);
Vue.component('TextInputNoValidation', TextInputNoValidation);
Vue.component('RadioButtonCtrl', RadioButtonCtrl);
Vue.component('PhonePrefixSelect', PhonePrefixSelect);
Vue.component('CountrySelect', CountrySelect);
Vue.component('BirthDayInput', BirthDayInput);
Vue.component('EmailSuggest', EmailSuggest);
Vue.component('LoginOverlay', LoginOverlay);
Vue.component('CulturePicker', CulturePicker);
Vue.component('HorizontalLine', HorizontalLine);
Vue.component('CheckboxSwitch', CheckboxSwitch);
Vue.component('UmbracoText', UmbracoText);
