import Vue from 'vue';
import serverContext from '@/core/serverContext.service';

Vue.filter('units', unitFilter);

function unitFilter(number: number | string, unit: 'dimension' | 'weight' | 'volume', maxDigits: number = 0, minDigits: number = 0): string {
    let fromUnit, toUnit, divisor;
    switch (unit) {
        case 'dimension':
            fromUnit = 'cm';
            toUnit = 'm';
            divisor = 100;
            break;
        case 'weight':
            fromUnit = 'g';
            toUnit = 'kg';
            divisor = 1000;
            break;
        case 'volume':
            fromUnit = 'ml';
            toUnit = 'l';
            divisor = 1000;
            break;
        default:
            throw new Error(unit + ' is not a supported unit measure');
    }

    const culture = serverContext.culture || 'da-DK';
    let theNumber = (typeof number === 'string' ? parseFloat(number) : number); // * baseModifier;
    if (isNaN(theNumber)) throw new Error(number + ' is not a valid number');

    let fixedFormattedNumber = '';
    if (theNumber >= divisor) {
        const numberFormatter = Intl.NumberFormat(culture, {
            minimumFractionDigits: minDigits,
            maximumFractionDigits: maxDigits || 2
        });
        fixedFormattedNumber = numberFormatter.format(theNumber / divisor);
        return `${fixedFormattedNumber} ${toUnit}`;
    } else {
        if (theNumber < 1 && theNumber > 0 && unit === 'dimension') {
            theNumber = theNumber * 10;
            fromUnit = 'mm';
            maxDigits = 2;
        }
        const numberFormatter = Intl.NumberFormat(culture, {
            minimumFractionDigits: minDigits,
            maximumFractionDigits: maxDigits
        });
        fixedFormattedNumber = numberFormatter.format(theNumber);
        return `${fixedFormattedNumber} ${fromUnit}`;
    }
}

export default unitFilter;
