<template>
    <article class="c-teaser-text text-center">
        <div class="c-teaser-text__content mx-auto"
             :style="maxTextWidth">
            <component :is="getHeaderTagType"
                       v-if="content.supplementaryTitle"
                       class="c-teaser-text__content-title"
                       :class="headerClass">
                {{ content.supplementaryTitle }}
            </component>
            <p v-if="content.text"
               class="c-teaser-text__content-text leading-sm text-17 pt-3 md:pt-2 md:text-24"
               :class="{'mb-30 md:mb-35': content.link}"
               v-html="textWithBrTags"/>
        </div>

        <RouterLinkConditional v-if="content.link"
                               :to="content.link.url"
                               :title="content.link.name"
                               :target="content.link && content.link.target"
                               class="c-btn c-btn--sm c-btn--themed-outlined inline-flex"
                               @click.native="trackPromotionClick">
            <span class="c-btn__text">
                {{ content.link.name }}
            </span>
        </RouterLinkConditional>
    </article>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { TeaserTextBlockModel } from '@/types/serverContract';
import { newLineToBr } from '@/project/shared/string.util';
import trackingUtils from '@/project/tracking/tracking.utils';

@Component({
    name: 'BlockTeaserText'
})
export default class BlockTeaserText extends Vue {
    @Prop() content!: TeaserTextBlockModel;

    get maxTextWidth() : Record<string, string> {
        const styling : any = {};

        if (this.content && this.content.maxTextWidth) {
            styling.width = `${this.content.maxTextWidth}%`;
        }

        return styling;
    }

    get textWithBrTags() : string {
        return newLineToBr(this.content.text);
    }

    get getHeaderTagType() : string {
        return this.content.headerType || 'h3';
    }

    get largeHeading(): boolean {
        return !!this.content.largeTitle;
    }

    get headerClass(): string {
        if (this.largeHeading) {
            return 'block font-normal leading-title text-30 md:text-40 md:px-30 uppercase font-brandon mb-20 md:mb-25';
        }
        return 'uppercase text-12 opacity-80 block mb-10';
    }

    trackPromotionClick() {
        try {
            trackingUtils.promotion.trackPromotionClick({
                componentName: this.$options.name,
                trackingName: this.content.trackingName,
                trackingTitle: this.content.supplementaryTitle || this.content.text,
                creativeText: this.content.link?.name || this.content.text,
                position: 0
            });
        } catch (e) { }
    }
}
</script>

<style lang="less" scoped>
    @import (reference) "../../../styling/0-Settings/index.less";
    .c-teaser-text__content {
        @apply mx-auto;
    }
    .c-teaser-text__content-title {
        letter-spacing: .7px;
    }
    .c-teaser-text__content-text {
        line-height: 1.53;
    }

    @media @maxTitleWidthOverwriteMediaQuery {
        .c-teaser-text__content {
            @apply max-w-620;
            width: auto !important;
        }
    }

    @screen lg {
        .c-teaser-text__content-title {
            letter-spacing: .3px;
        }
        .c-teaser-text__content-text {
            line-height: 1.5;
            letter-spacing: -.1px;
        }
    }
</style>
