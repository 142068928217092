<template>
    <div class="flex flex-col items-start gap-4">
        <template v-if="selectedVariant.packageQuantity && selectedVariant.packageQuantity > 1">
            <SplitTest feature-key="Fsst-package_quantity"
                       default-variation="disabled"
                       is-fallback>
                <div class="flex items-center gap-8 font-gibson text-20 font-medium leading-26">
                    {{ unitPrice }}
                </div>
            </SplitTest>
            <SplitTest feature-key="Fsst-package_quantity"
                       variation="enabled"
                       default-variation="disabled">
                <div class="flex items-center gap-8 font-gibson text-20 font-medium leading-26">
                    {{ packageComparisonPrice }}
                </div>
                <div class="font-gibson text-12 font-light leading-16 text-brown-60">
                    {{ selectedVariant?.pricing?.packageDescriptionLabel }}
                </div>
            </SplitTest>
        </template>
        <div v-else
             class="flex items-center gap-8 font-gibson text-20 font-medium leading-26">
            {{ unitPrice }}
        </div>
        <div v-if="comparisonPrice || ecoChargePrice || additionalShippingInfo"
             class="flex flex-col items-start">
            <div v-if="comparisonPrice"
                 class="font-gibson text-12 font-light leading-16 text-brown-60">
                {{ comparisonPrice }}
            </div>
            <EcoChargeButton v-if="ecoChargePrice"
                             :selected-variant="selectedVariant"
                             :text-theme-color="'text-brown-60'"/>
            <ShippingTextLink v-if="isAvailableForPurchase && additionalShippingInfo"
                              :shipping-info="additionalShippingInfo"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ShippingTextLink from '@/project/product/ShippingTextLink.vue';
import EcoChargeButton from '@/project/product/EcoChargeButton.vue';
import { v4 } from '@/types/serverContract';
import serverContext from '@/core/serverContext.service';
import SplitTest from '@/project/growthbook/SplitTest.vue';

const props = defineProps<{
    product: v4.Products.ProductSimple;
    selectedVariant: v4.Products.VariantSimple;
}>();

const additionalShippingInfo = computed(() => props.product.additionalShippingInfo);
const unitPrice = computed(() => props.selectedVariant.pricing.unit.formatted);
const packageComparisonPrice = computed(() => props.selectedVariant.pricing?.packageComparison?.formatted);
const comparisonPrice = computed(() => props.selectedVariant.pricing.comparison?.formatted);
const ecoChargePrice = computed(() => props.selectedVariant.pricing.ecoCharge?.formatted);
const isEcomMarket = computed(() => serverContext.checkoutContext.hasCheckout);

const isAvailableForPurchase = computed(() => {
    return isEcomMarket.value && props.selectedVariant?.inventory?.inStock > 0;
});
</script>
