import { IImageOptions } from './image.types';

const sizes: Array<number> = [];

export function addSize(width: number) {
    sizes.push(width);
}

function getSizes() {
    return sizes.length ? sizes : [320, 640, 1024, 1600];
}

export function generateCdnUrlWithParams(baseUrl: string, sourceUrl: string, options: IImageOptions) {
    const params = convertOptionsToParams(options);

    return `${baseUrl}/${params}/${sourceUrl}`;
}

function convertOptionsToParams(options: IImageOptions): string {
    return Object.entries(options).reduce((acc, [key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
            acc.push(`${key}=${encodeURIComponent(value)}`);
        }
        return acc;
    }, [] as string[]).join(',');
}

export default {
    getSizes
};
