import { ref } from 'vue';

export const useToggle = (initialValue: boolean = false) => {
    const value = ref<boolean>(initialValue);

    const open = () => (value.value = true);
    const close = () => (value.value = false);
    const toggle = () => (value.value = !value.value);

    return {
        value,
        open,
        close,
        toggle
    };
};

export default useToggle;
