function isEscape(event:KeyboardEvent) {
    return event && (event.key === 'Escape' || event.key === 'Esc');
}

function isRightArrow(event:KeyboardEvent) {
    return event && (event.key === 'ArrowRight' || event.key === 'Right');
}

function isLeftArrow(event:KeyboardEvent) {
    return event && (event.key === 'ArrowLeft' || event.key === 'Left');
} export default {
    isEscape,
    isRightArrow,
    isLeftArrow
};
