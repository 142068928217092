<template>
    <div class="mb-40 bg-sand-40 py-40 md:py-64">
        <div class="o-container">
            <div class="mb-24 flex flex-col gap-10 pr-40 md:items-center md:pr-0">
                <h2 class="font-script mb-0 text-40 leading-42">
                    {{ $translate('productDetails.Projects.Heading') }}
                </h2>
                <p
                    class="font-brandon text-16 font-medium uppercase leading-22 md:text-24 md:font-normal md:leading-28">
                    {{ $translate('productDetails.Projects.Intro') }}
                </p>
            </div>
            <div class="flex flex-col gap-48 md:flex-row md:flex-wrap md:gap-54">
                <div
                    v-for="(project, idx) in firstTwoProjects"
                    :key="idx"
                    class="flex flex-col gap-16 md:gap-24"
                    :class="{
                        'flex-1': firstTwoProjects.length === 1,
                        'c-projects-item md:flex-shrink-0 md:flex-grow-0': firstTwoProjects.length > 1
                    }">
                    <ResponsiveImage
                        mode="crop"
                        :image-url="project.image.url"
                        :alt="project.image.name"
                        :focal-point="project.image.focalPoint"
                        :aspect-ratio="aspectRatio"
                        :width-on-screen="widthOnScreen"/>
                    <div class="flex flex-col gap-8">
                        <h3 class="mb-0 font-brandon text-14 font-medium uppercase leading-16 md:text-20 md:leading-24">
                            {{ project.title }}
                        </h3>
                        <p
                            class="c-projects-item-text max-w-440 font-gibson text-14 leading-20 md:text-16 md:leading-22">
                            {{ truncate(project.text) }}
                        </p>
                    </div>
                    <div v-if="isUrlLink(project.link)">
                        <RouterLink
                            :to="project.link.url"
                            class="font-brandon text-12 font-medium uppercase leading-none tracking-medium underline hover:no-underline md:text-14">
                            {{ $translate('productDetails.Projects.LinkText') }}
                        </RouterLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { v4 } from '@/types/serverContract';
import { isUrlLink } from '@/project/shared/link/linkUtil';
const props = defineProps<{
    projects: v4.Products.DiyProjectTeaser[];
}>();

const firstTwoProjects = computed(() => props.projects.slice(0, 2));

const aspectRatio = computed(() => {
    if (firstTwoProjects.value.length === 1) {
        return {
            default: 1 / 1,
            'max-lg': 1 / 1,
            'min-md': 1320 / 737
        };
    }

    return 1 / 1;
});

const widthOnScreen = computed(() => {
    if (firstTwoProjects.value.length === 1) {
        return 100;
    }

    return { lg: 50 };
});

const truncate = (text: string, maxLength: number = 160) => {
    if (text.length > maxLength) {
        return text.slice(0, maxLength) + '…';
    }

    return text;
};
</script>

<style scoped>
.c-projects-item-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
            line-clamp: 3;
    -webkit-box-orient: vertical;
}

@screen md {
    .c-projects-item {
        width: calc(50% - 2.7rem);
    }
}
</style>
