<template>
    <article class="flex flex-wrap lg:flex-nowrap text-white-325 mt-56 lg:mt-60 lg:mx-10% lg:px-10"
             :class="showAppAndBadges ? 'mb-70 md:mb-33': 'mb-90'">
        <div v-if="showAppAndBadges"
             class="w-full md:w-1/2 flex">
            <div class="w-full">
                <div class="flex w-full">
                    <div class="w-1/2 md:w-auto relative inline-block pr-30 md:pr-62">
                        <div class=" overflow-hidden relative">
                            <!-- PS: In previous implementation, the container was clipped using border-radius: 10% / 5.5%.
                                     That was a more elegant solution than painting brown corners on it. However, for some reason,
                                     it was failing on IOS Safari (#5454). -->
                            <div v-if="appInfoBlockImage && appInfoBlockImage.url"
                                 class="absolute top-0 bottom-0"
                                 style="left:3%; right:3%">
                                <img :src="formattedBlockImageUrl"
                                     class="w-full parallax-img"
                                     @load="setParallaxImageLoaded">
                            </div>
                            <img src="/images/phone-frame.svg"
                                 class="w-full md:w-auto relative frame"
                                 aria-hidden="true"
                                 @load="setFrameImageLoaded">
                            <!-- Add brown corners above. The negative inset is to avoid a thin line on IOS Safari -->
                            <div class="absolute z-2 -inset-2">
                                <svg viewBox="0 0 161 316"
                                     xmlns="http://www.w3.org/2000/svg"
                                     aria-hidden="true">
                                    <mask id="themask">
                                        <!-- white makes visible -->
                                        <rect x="-5"
                                              width="171"
                                              y="-5"
                                              height="326"
                                              fill="white"/>
                                        <!-- black makes invisible -->
                                        <rect x="5"
                                              y="5"
                                              width="151"
                                              height="303"
                                              rx="15"
                                              fill="black"/>
                                    </mask>
                                    <rect x="-5"
                                          y="-5"
                                          width="171"
                                          height="326"
                                          fill="#504139"
                                          mask="url(#themask)"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/2 md:w-auto flex flex-col items-center">
                        <div class="flex flex-col items-center justify-center max-w-170 h-full pr-10">
                            <span class="text-center">{{ appInfoBlockText }}</span>
                            <a v-if="showAppleStoreBadge"
                               :href="serverContextService.appAppleStoreHref"
                               target="_blank"
                               class="w-125 mt-30">
                                <img :src="serverContextService.appAppleStoreImage?.url"
                                     :width="133"
                                     :alt="serverContextService.appAppleStoreImage?.name">
                            </a>
                            <a v-if="showGoogleStoreBadge"
                               :href="serverContextService.appGoogleStoreHref"
                               target="_blank"
                               class="w-125 mt-10">
                                <img :src="serverContextService.appGoogleStoreImage?.url"
                                     :width="133"
                                     :alt="serverContextService.appGoogleStoreImage?.name">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div :class="'md:mt-0 w-full flex flex-col items-center text-center  border-white-325 border-opacity-25 ' + (showAppAndBadges ? 'md:w-1/2 mt-100 md:border-l': 'md:mt-20')">
            <h3 class="text-24 font-brandon uppercase mt-3">
                {{ annaAndClaraBlockTitle }}
            </h3>
            <p class="w-272 pt-6">
                {{ annaAndClaraBlockText }}
            </p>
            <c-icon name="logo"
                    height="125"
                    :class="showAppAndBadges ? 'mt-25' : 'order-first mb-56'"/>

            <RouterLinkConditional v-if="annaAndClaraBlockLink && annaAndClaraBlockLink.url"
                                   :to="annaAndClaraBlockLink.url"
                                   class="mt-40"
                                   :title="annaAndClaraBlockLink.name"
                                   :target="annaAndClaraBlockLink.target">
                <div class=" text-white-325 inline-block leading-zero">
                    <span class="text-14 leading-18 text-normal inline-block leading-zero">{{ annaAndClaraBlockLink.name }}</span>
                    <c-icon name="chevron"
                            height="15"
                            class="ml-7 u-rotate-180"/>
                </div>
            </RouterLinkConditional>
        </div>
    </article>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import { Prop } from 'vue-property-decorator';
import { Link, Media } from '@/types/serverContract';
import serverContextService from '@/core/serverContext.service';

@Component
export default class AppInfoAndMeetAnnaAndClara extends Vue {
    frameImgLoaded = false;
    parallaxImgLoaded = false;

    @Prop()
        annaAndClaraBlockLink!: Link;

    @Prop()
        annaAndClaraBlockText!: string;

        @Prop()
            annaAndClaraBlockTitle!: string;

    @Prop()
        appInfoBlockText!: string;

    @Prop()
        appInfoBlockImage!: Media;

    setFrameImageLoaded() {
        this.frameImgLoaded = true;
        this.tryInitParallax();
    }

    setParallaxImageLoaded() {
        this.parallaxImgLoaded = true;
        this.tryInitParallax();
    }

    get serverContextService() {
        return serverContextService;
    }

    get formattedBlockImageUrl(): string {
        return `${this.appInfoBlockImage.url}?format=webp&quality=75`;
    }

    // TODO:
    // Check up if this is correct condition, see question by bro in #5544
    get showAppAndBadges() {
        return this.showAppleStoreBadge && this.showGoogleStoreBadge;
    }

    get showAppleStoreBadge() {
        return (this.serverContextService?.appAppleStoreImage?.url && serverContextService.appAppleStoreHref);
    }

    get showGoogleStoreBadge() {
        return (serverContextService?.appGoogleStoreImage?.url && serverContextService.appGoogleStoreHref);
    }

    tryInitParallax() {
        if (!(this.parallaxImgLoaded && this.frameImgLoaded)) return;

        function getMaxParallelOffset(): number {
            const frameHeight = document.getElementsByClassName('frame')[0].clientHeight;
            const parallaxImgHeight = document.getElementsByClassName('parallax-img')[0].clientHeight;
            return parallaxImgHeight - frameHeight;
        }

        let pageHeight = document.body.clientHeight;
        gsap.registerPlugin(ScrollTrigger);
        gsap.to('.parallax-img', {
            y: () => `-${getMaxParallelOffset()}px`,
            scrollTrigger: {
                start: 'top 80%',
                end: 'top 20%',
                markers: false,
                trigger: '.frame',
                scrub: true,
                invalidateOnRefresh: true
            }
        });

        // Make sure offsets are calculated correctly when page slowly builds up and gets higher
        function rebuildScrollTrigger() {
            const newPageHeight = document.body.clientHeight;
            if (newPageHeight !== pageHeight) {
                ScrollTrigger.refresh();
                pageHeight = newPageHeight;
            }
        }
        setInterval(rebuildScrollTrigger, 2000);
    }
}
</script>
