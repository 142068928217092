<template>
    <div class="image-width w-full relative mx-auto">
        <div class="relative">
            <Transition mode="out-in"
                        enter-active-class="animated fadeIn u-anim-dur-1000">
                <button
                    v-if="enablePrevSlideButton"
                    class="absolute inset-y-0 left-0 -ml-32 hidden md:block"
                    :aria-label="$translate('generic.Prev')"
                    @click="prevSlide">
                    <cIcon name="chevron"
                           class="w-20"/>
                </button>
            </Transition>
            <div ref="imageContainerWrapRef"
                 class="relative image-container flex">
                <div v-for="(image, i) in selectedVariantImages"
                     :ref="(el) => (imageContainerRef[i] = el)"
                     :key="i"
                     :image-type="image.imageType"
                     :image-index="i"
                     class="child-container">
                    <ResponsiveImage class="u-image-zoom w-full"
                                     :class="{'c-on-error': !image }"
                                     :width-on-screen="80"
                                     :aspect-ratio="294 / 373"
                                     :image-url="image?.url"
                                     :focal-point="image && image.focalPoint || null"
                                     :alt="image?.name"
                                     bg-color="#faf7f0"/>
                    <RouterLink :to="product.url"
                                :title="props.product.name"
                                class="absolute inset-0"
                                tabindex="-1"
                                @click.native="handleClick($event, product)"/>
                </div>
            </div>
            <Transition mode="out-in"
                        enter-active-class="animated fadeIn u-anim-dur-1000">
                <button
                    v-if="enableNextSlideButton"
                    class="absolute inset-y-0 right-0 -mr-32 hidden md:block"
                    :aria-label="$translate('generic.Next')"
                    @click="nextSlide">
                    <cIcon name="chevron"
                           class="w-20 u-rotate-180 "/>
                </button>
            </Transition>
            <Transition
                mode="out-in"
                leave-active-class="animated fadeOut u-anim-dur-200"
                enter-active-class="animated fadeIn u-anim-dur-200">
                <ProductTileIcons class="absolute mr-8 mb-8 md:mr-12 md:mb-12 right-0 bottom-0 z-5 transform transition-all duration-300 delay-300 will-change-transform"
                                  :selected-variant="selectedVariant"
                                  :is-energy-icon="isEnergyIcon"
                                  :show-icons="!environmentalImageInView"
                                  has-label="productTileHasLabel"/>
            </Transition>
        </div>
        <div class="h-4 mt-8 md:mt-16">
            <CarouselDots
                v-if="selectedVariantImages.length > 1"
                :slides="selectedVariantImages.length"
                :active-index="currentImageIndex"
                :increase-selected-size="false"
                class="mx-auto leading-zero text-center pointer-events-none"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { v4, ImageType } from '@/types/serverContract';
import ProductTileIcons from '@/project/product/ProductTileIcons.vue';
import CarouselDots from '@/project/product/details/CarouselDots.vue';
import useProductTracking from '@/project/product/useProductTracking';
import { useRouter } from 'vue-router/composables';

const props = defineProps<{
    product: v4.Products.ProductSimple;
    selectedVariant: v4.Products.VariantSimple;
}>();

const imageContainerWrapRef = ref<HTMLElement | null>(null);
const imageContainerRef = ref<HTMLElement[]>([]);
const environmentalImageInView = ref(false);
const currentImageIndex = ref(0);
const observer = ref< IntersectionObserver | null>(null);
const router = useRouter();
const { trackProductClick } = useProductTracking(props);

const setupObserver = () => {
    observer.value = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setItemInView(entry);
                }
            });
        },
        { root: imageContainerWrapRef.value, rootMargin: '', threshold: 0.5 }
    );

    imageContainerRef.value.forEach(el => {
        if (observer.value && el) {
            observer.value.observe(el);
        }
    });
};

watch(() => imageContainerWrapRef.value, (newVal) => {
    if (newVal) {
        setupObserver();
    }
}, { deep: true });

const enableNextSlideButton = computed(() => {
    return currentImageIndex.value !== selectedVariantImages.value.length - 1;
});

const enablePrevSlideButton = computed(() => {
    return currentImageIndex.value !== 0;
});

const nextSlide = () => {
    if (enableNextSlideButton.value) {
        handleSlide(currentImageIndex.value + 1);
    }
};

const prevSlide = () => {
    if (enablePrevSlideButton.value) {
        handleSlide(currentImageIndex.value - 1);
    }
};

const handleSlide = (delta) => {
    if (imageContainerWrapRef.value) {
        imageContainerWrapRef.value.scroll({
            top: 0,
            left: imageContainerWrapRef.value.clientWidth * delta,
            behavior: 'smooth'
        });
    }
};

const selectedVariantImages = computed(() => {
    return props.selectedVariant?.media;
});

const isEnergyIcon = computed(() => {
    return props.selectedVariant?.labellingsFlattened?.some(i => i.type === v4.Products.LabelType.EnergyClass);
});

const handleClick = (event, product: v4.Products.ProductSimple) => {
    trackProductClick(event, product, props.selectedVariant);
    router.push({ path: product.url, query: { variant: props.selectedVariant.sku } });
};

function setItemInView(entry: IntersectionObserverEntry) {
    // We want to hide icons if image in view is ImageType.Environmental,
    // therefore we set below boolean
    // Index is used for image indicator and nav
    const imageType = parseInt(entry.target.attributes?.['image-type'].value);
    const imageIndex = parseInt(entry.target.attributes?.['image-index'].value);
    currentImageIndex.value = imageIndex;
    environmentalImageInView.value = imageType === ImageType.Environmental;
}

</script>

<style lang="less" scoped>

.image-width {
    max-width: 16.7rem;
}

.variant-wrap {
    @apply relative z-3 min-h-full h-full my-auto px-20 w-full flex flex-col;
}

.image-container {
    width: 100%;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    white-space: nowrap;
    display: flex;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.child-container {
    scroll-snap-align: center;
    scroll-snap-stop: always;
    flex: 0 0 auto;
    width: 100%;
    @apply relative;
}

@screen md {
    .image-width {
        max-width: 29.4rem;
    }

}
</style>
