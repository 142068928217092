<template>
    <div class="c-usps relative px-12 py-32 md:px-24 md:py-32">
        <div class="relative z-1 flex flex-col items-start gap-16">
            <div v-for="(usp, idx) in model"
                 :key="`usp-${idx}`"
                 class="font-gibson text-14 font-medium leading-18">
                <div class="flex items-start gap-8">
                    <img
                        :src="usp.logo?.svg?.url || usp.logo?.png?.url"
                        :alt-text="usp.logo?.svg?.name || usp.logo?.png?.name"
                        :width="20"
                        class="c-usps-icon">
                    <span v-if="usp.text"
                          class="flex-1">
                        <UmbracoText class="c-usps-text"
                                     :text="usp.text"/>
                        <template v-if="usp.link">&nbsp;</template>
                        <RouterLinkConditional v-if="isUrlLink(usp.link)"
                                               :to="usp.link.url"
                                               class="underline hover:no-underline">
                            <template v-if="usp.link.text">{{ usp.link.text }}</template>
                            <template v-else>{{ $translate('generic.ReadMore') }}</template>
                        </RouterLinkConditional>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { v4 } from '@/types/serverContract';
import { isUrlLink } from '@/project/shared/link/linkUtil';

const props = defineProps<{
    content?: v4.Products.UniqueSellingPoint[];
    maxUsps?: number;
}>();

const model = props.maxUsps !== undefined ? props.content?.slice(0, props.maxUsps) : props.content;

</script>

<style scoped>
.c-usps::after {
    content: '';
    @apply absolute inset-y-0 left-1/2 right-1/2 -mx-1/2-screen block w-screen bg-sand-20;
}

@screen md {
    .c-usps::after {
        @apply inset-0 m-0 w-full;
    }
}

.c-usps-icon {
    transform: translateY(-0.2rem);
    flex: 0 0 2rem;
}

.c-usps-text {
    @apply whitespace-pre-line;
    overflow-wrap: anywhere;
}
</style>
