import Vue from 'vue';
import ProductProvider from './ProductProvider.vue';
import { blockResolver } from '@/core/spa/componentResolver.service';
const ProductTile = () => import('./ProductTile.vue');
const FeaturedProductBlock = () => import('./FeaturedProductBlock.vue');
const RecommendationProductBlock = () => import('./RecommendedProductBlock.vue');
const UspBlock = () => import('./UspBlock.vue');
Vue.component('ProductProvider', ProductProvider);
Vue.component('ProductTile', ProductTile);
blockResolver.register('featuredProductBlock', FeaturedProductBlock);
blockResolver.register('recommendedProductEnrichmentBlock', RecommendationProductBlock);
blockResolver.register('uspBlock', UspBlock);
blockResolver.register('featuredProductEnrichmentBlock', FeaturedProductBlock);
blockResolver.register('uspEnrichmentBlock', UspBlock);
