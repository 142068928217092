<template>
    <article class="c-header text-center">
        <div class="c-header__content mx-auto">
            <header v-if="showHeaderTag"
                    class="c-header__header-wrap mx-auto"
                    :style="setMaxTitleWidth">
                <div v-if="content.image && content.image.url"
                     class="relative z-0 px-20 ls:px-40 md:px-60 c-header__icon"
                     :class="getImageClass">
                    <responsive-icon-wrap
                        :image="content.image"
                        :bg-color="content.backgroundColor || ''"
                        :alt-text="altText"/>
                </div>
                <div class="c-header__header z-2 relative">
                    <component :is="getHeaderTagType"
                               v-if="content.title || content.supplementaryTitle"
                               :id="getHeaderAnchorLink"
                               class="uppercase font-brandon"
                               :class="{'mb-20 md:mb-25': content.text, 'mb-0': !content.text}">
                        <span v-if="content.supplementaryTitle"
                              class="block text-10 mb-15 md:text-13 md:mb-30">{{ content.supplementaryTitle }}</span>
                        <span v-if="content.title"
                              class="block font-normal leading-title text-30 md:text-40 md:px-30">
                            {{ content.title }}
                        </span>
                    </component>
                </div>
            </header>
            <div v-if="content.text"
                 v-delegate-html-links
                 :style="setMaxTextWidth"
                 class="mx-auto c-header__body wysiwyg"
                 v-html="content.text"/>
        </div>
    </article>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { HeaderBlockModel } from '@/types/serverContract';
import { getHeaderAnchorLink } from '@/project/shared/string.util';

@Component({
    name: 'BlockHeader'
})
export default class BlockHeader extends Vue {
    @Prop() content!: HeaderBlockModel;

    get setMaxTextWidth() : Record<string, string> {
        const styling : any = {};

        if (this.content && this.content.maxTextWidth) {
            styling.width = `${this.content.maxTextWidth}%`;
        }

        return styling;
    }

    get setMaxTitleWidth() : Record<string, string> {
        const styling : any = {};

        if (this.content && this.content.maxTitleWidth) {
            styling.width = `${this.content.maxTitleWidth}%`;
        }

        return styling;
    }

    get getHeaderTagType() : string {
        return this.content.headerType || 'h3';
    }

    get getImageClass(): string {
        if (this.content.imageAlignment === 'left') {
            return '-mb-20 mr-auto text-left';
        } else if (this.content.imageAlignment === 'right') {
            return '-mb-20 ml-auto text-right';
        } else {
            return 'text-center mb-20';
        }
    }

    get getHeaderAnchorLink(): string | undefined {        return getHeaderAnchorLink(this.content);
    }

    get altText(): string | null {
        return this.content.supplementaryTitle || this.content.title ? [this.content.supplementaryTitle, this.content.title].join(' - ') : null;
    }

    get showHeaderTag(): boolean {
        return !!(this.content.image || this.content.title || this.content.supplementaryTitle);
    }
}
</script>

<style lang="less" scoped>
@import (reference) "../../../styling/0-Settings/index.less";
@media @maxTitleWidthOverwriteMediaQuery {
    .c-header__body,
    .c-header__header-wrap {
        width: auto !important;
        @apply max-w-620;
    }
}
.c-header__icon {
    img {
        transform: scale(.73);
        transform-origin: bottom;
    }
}
@screen md {
    .c-header__icon {
        img {
            transform: scale(1);
        }
    }
}
</style>
