import Vue from 'vue';
Vue.directive('submit', function(el, binding, vnode) {
    if (!(typeof binding.value === 'function')) {
        throw new Error('You must supply a submit-handler function');
    }
    if (!(vnode.context as any).$validator) {
        throw new Error('V-validate "$validator" object should exist in your Vue component containing the form');
    }
    (el as HTMLFormElement).noValidate = true;
    (el as HTMLFormElement).onsubmit = e => {
        e.preventDefault();
        // vnode.context is the Vue the form is located in.
        const context = (vnode.context as any);
        const validationScopes = [context.$validator.validateAll()];
        context.$children.forEach(vm => {
            validationScopes.push(vm.$validator.validateAll());
        });
        Promise.all(validationScopes).then((validatedScopes: boolean[]) => {
            const invalidScopes = validatedScopes.filter(isValid => { return !isValid; });
            const hasOnlyValidScopes = invalidScopes.length < 1;
            if (hasOnlyValidScopes) {
                binding.value();
            }
        });
    };
});
