import IPortalOverlay from '@/core/portal-overlay/IPortalOverlay';

const activeOverlay: IPortalOverlay[] = [];

async function beginActivation(overlay: IPortalOverlay): Promise<void> {
    // Look for overlay in same portal
    const overlaysToWaitFor = activeOverlay.find((o) => o.portal === overlay.portal);
    if (overlaysToWaitFor) {
        await overlaysToWaitFor.close();
    }
    activeOverlay.push(overlay);
}

function cleanup(overlay: IPortalOverlay) {
    // Remove overlay by id
    const overlayIndex = activeOverlay.findIndex((o) => o.id === overlay.id);
    if (activeOverlay.length) {
        activeOverlay.splice(overlayIndex, 1);
    }
}

export default {
    beginActivation,
    cleanup
};
