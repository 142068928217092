<template>
    <div>
        <button
            class="nav-toggle-btn"
            :aria-label="$translate('generic.Open')"
            @click="openNavigation">
            <span/>
            <span/>
            <span/>
        </button>
    </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['on-open-navigation']);

const openNavigation = () => {
    emit('on-open-navigation');
};

</script>

<style lang="less" scoped>
.nav-toggle-btn {
    @apply pr-2 pt-5 pb-5 mr-15 flex flex-col;

    &>span {
        @apply block w-20 h-1 bg-brown-80 opacity-100;
        transition: opacity .3s ease, transform .3s cubic-bezier(.215, .61, .355, 1);
        margin-bottom: 0.5rem;

        &:last-child {
            @apply mb-0;
        }
    }
}

</style>