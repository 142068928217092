<template>
    <portal v-if="active"
            :to="portal">
        <div class="c-portal-overlay fixed inset-0"
             :class="[zClass, outerClass]"
             role="dialog">
            <transition
                appear
                :enter-active-class="animationEnterClass"
                :leave-active-class="animationLeaveClass"
                @after-enter="contentVisible"
                @after-leave="cleanup">
                <div
                    v-if="visible"
                    :key="id"
                    ref="contentWrapper"
                    class="c-portal-overlay__content-wrapper u-scroll-bar-hide fixed w-full overflow-x-hidden"
                    :class="[overlayClasses, { 'portal-content-is-scrolled': scrollPosition }]"
                    @scroll="debounceScroll">
                    <slot v-if="showCloseButton && showStickyHeader && !disableUserClose"
                          name="header">
                        <div class="c-portal-overlay__header">
                            <button
                                class="c-portal-overlay__close"
                                :aria-label="$translate('generic.Close')"
                                @click="userCancel()">
                                <c-icon name="close"
                                        width="16"/>
                            </button>
                        </div>
                    </slot>
                    <button
                        v-else-if="showCloseButton && !disableUserClose"
                        class="c-portal-overlay__close"
                        :aria-label="$translate('generic.Close')"
                        @click="userCancel()">
                        <c-icon name="close"
                                width="16"/>
                    </button>
                    <slot :scroll-position="scrollPosition"/>
                </div>
            </transition>
            <slot name="footer"/>
            <transition
                appear
                enter-active-class="animated fadeIn u-anim-dur-300"
                leave-active-class="animated fadeOut u-anim-dur-1000">
                <page-blind v-if="visible && showBlind"
                            :click-handler="userCancel"/>
            </transition>
        </div>
    </portal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { SCROLL_TO_TOP } from '@/project/config/constants';
import bus from '@/core/bus';
import keyboardService from '@/core/keyboard.service';
import overlayOrchestrator from './overlayOrchestrator';
import scrollService from '@/core/scroll/scroll.service';
import Deferred from '@/core/async/deferred';
import IPortalOverlay from './IPortalOverlay';
import PageBlind from '@/project/layout/page-blind/PageBlind.vue';
import throttle from 'lodash-es/throttle';

@Component({
    components: {
        PageBlind
    }
})
export default class PortalOverlay extends Vue implements IPortalOverlay {
    state: 'ready' | 'waiting_for_other_portal' | 'visible' | 'animating_out' = 'ready';
    animatedOutDeferred: Deferred<void> | null = null;
    scrollPosition: number = 0;
    debounceScroll = throttle((e) => this.handleScroll(e), 100);

    @Prop({
        type: String,
        default: 'fade',
        validator: (value) => ['left', 'right', 'top', 'center', 'fade', 'bottom'].includes(value as string)
    })
        side!: string;

    @Prop({ type: String, default: 'overlay' })
    public portal!: string;

    @Prop({ type: Boolean, default: false })
        show!: boolean;

    @Prop({ type: Boolean, default: false })
        showStickyHeader!: boolean;

    @Prop({ type: Boolean, default: true })
        showCloseButton!: boolean;

    @Prop({ type: Boolean, default: true })
        showBlind!: boolean;

    @Prop({ type: Boolean, default: true })
        contentAutoScroll!: boolean;

    @Prop({ type: Boolean, default: true })
        disableBodyScroll!: boolean;

    @Prop({ type: String, default: '' })
        outerClass!: string;

    @Prop({ type: String, default: 'z-overlay' })
        zClass!: string;

    @Prop({ type: String, default: '' })
        wrapperClass!: string;

    @Prop({ type: Boolean, default: false })
        disableUserClose!: boolean;

    @Prop({ type: Boolean, default: false })
        closeOnRouteChange!: boolean;

    @Prop({ type: Function })
    private vetoClose!: () => Promise<boolean>;

    public get id() {
        return this.portal + new Date().valueOf();
    }

    get active() {
        return this.state === 'visible' || this.state === 'animating_out';
    }

    get visible() {
        return this.state === 'visible';
    }

    created() {
        bus.on(SCROLL_TO_TOP, this.scrollTo);
        if (this.show) {
            this.doShow(true);
        }
    }

    destroyed() {
        bus.off(SCROLL_TO_TOP, this.scrollTo);
    }

    @Watch('$route')
    onRouteChange() {
        if (this.closeOnRouteChange) {
            this.close();
        }
    }

    @Watch('show')
    onShowChange(show: boolean) {
        this.doShow(show);
    }

    async doShow(show: boolean) {
        if (show && this.state === 'ready') {
            // Await that a another PortalOverlay using same portal has animated out.
            this.state = 'waiting_for_other_portal';
            await overlayOrchestrator.beginActivation(this as IPortalOverlay);
            this.state = 'visible';
        } else if (!show) {
            this.close();
        }
    }

    public close(): Promise<void> {
        if (this.state !== 'visible') return Promise.resolve(); // Just safeguarding
        this.state = 'animating_out';

        this.$emit('visible', false);
        document.removeEventListener('keyup', this.keyUp);
        if (this.disableBodyScroll) {
            scrollService.enableBodyScroll(this.$refs.contentWrapper);
        }

        this.animatedOutDeferred = new Deferred<void>();
        return this.animatedOutDeferred.promise;
    }

    cleanup() {
        this.state = 'ready';
        this.animatedOutDeferred && this.animatedOutDeferred.resolve();
        this.$emit('update:show', false);
        overlayOrchestrator.cleanup(this as IPortalOverlay);
    }

    contentVisible(el: Element) {
        this.$emit('visible', true);
        document.addEventListener('keyup', this.keyUp);
        if (this.disableBodyScroll) {
            scrollService.disableBodyScroll(el as HTMLElement, {
                allowTouchMove: (el) => {
                    while (el && el !== document.body) {
                        if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                            return true;
                        }

                        el = el.parentElement;
                    }
                }
            });
        }
    }

    beforeDestroy() {
        if (this.state !== 'visible') return; // All other states are very temporary - no handling...
        this.close();
        this.cleanup();
    }

    get overlayClasses() {
        return {
            [this.wrapperClass]: true,
            [this.side]: true,
            'overflow-y-scroll md:overflow-y-scroll': this.contentAutoScroll
        };
    }

    keyUp(event) {
        if (!keyboardService.isEscape(event)) {
            return;
        }
        this.userCancel();
    }

    userCancel() {
        if (this.disableUserClose) {
            return;
        }
        const vetoClose = this.vetoClose ? this.vetoClose() : Promise.resolve(false);
        vetoClose.then((doVetoClose) => {
            if (!doVetoClose) {
                this.$emit('cancelled');
                this.close();
            }
        });
    }

    get animationEnterClass() {
        const animTypes = {
            left: 'slideInLeftOpacity',
            right: 'slideInRightOpacity',
            top: 'slideInDown',
            bottom: 'slideInUp',
            fade: 'fadeIn'
        };
        return this.animationClass(animTypes);
    }

    get animationLeaveClass() {
        const animTypes = {
            left: 'slideOutLeftOpacity',
            right: 'slideOutRightOpacity',
            top: 'slideOutDown',
            bottom: 'slideOutDown',
            fade: 'fadeOut'
        };
        return this.animationClass(animTypes);
    }

    animationClass(animTypes: { [side: string]: string }) {
        return `animated ${animTypes[this.side]} u-anim-delay-100 u-anim-dur-300 md:u-anim-dur-600`;
    }

    handleScroll(e) {
        this.scrollPosition = e.target.scrollTop;
    }

    scrollTo(scrollVal = 0) {
        if (this.$refs.contentWrapper) {
            this.$refs.contentWrapper.scrollTo({ top: scrollVal, behavior: 'smooth' });
        }
    }

    $refs!: Vue['$refs'] & {
        contentWrapper: HTMLElement;
    };
}
</script>

<style lang="less">
// Fixes Chrome-browser rendering issue, where content underneath overlay wasn't rendered after anim out
@keyframes slideInRightOpacity {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
        opacity: 0;
        animation-duration: 300ms;
    }

    1% {
        opacity: 0.9;
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.slideInRightOpacity {
    -webkit-animation-name: slideInRightOpacity;
    animation-name: slideInRightOpacity;
}

.slideOutRightOpacity {
    -webkit-animation-name: slideInRightOpacity;
    animation-name: slideInRightOpacity;
    animation-direction: reverse;
}

@keyframes slideInLeftOpacity {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
        opacity: 0;
        animation-duration: 300ms;
    }

    1% {
        opacity: 0.9;
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.slideInLeftOpacity {
    -webkit-animation-name: slideInLeftOpacity;
    animation-name: slideInLeftOpacity;
}

.slideOutLeftOpacity {
    -webkit-animation-name: slideInLeftOpacity;
    animation-name: slideInLeftOpacity;
    animation-direction: reverse;
    animation-duration: 200ms;
}
</style>
