const el = document.createElement('div');

/** Sanitize strings that comes from ordinary Umbraco text fields
   *
   * We allow the customer to insert <wbr> and &shy; in ordinary text fields.
   * So these are preserved.
   *
   * The customer can insert line breaks in multiline fields.
   * These are converted to <br> tags
   *
   * The encoding of HTML is achieved by setting textContent of a DOM node and then reading innerHTML.
   * This is double as fast as doing regex.
   * The result is that "<", ">" and "&" are encoded. Quotes are encoded in some browsers.
   *
   */
export default function sanitizeUmbracoText(html:string):string {
    el.textContent = html;

    return el.innerHTML
        .replace(/&amp;shy;/gi, '&shy;')
        .replace(/&lt;WBR&gt;/gi, '<wbr>')
        .replace(/\n/g, '<br>')
        .replace(/&lt;br&gt;/gi, '<br>');
}

/**
 *  TODO: A function "sanitizeUmbracoHTML" for sanitizing strings that comes from Umbraco rich text fields
 *
 */
