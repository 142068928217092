<template>
    <div v-if="image || vimeoVideoId"
         class="c-hero-banner bg-white-100"
         :class="{ 'c-hero-banner__no-max': vimeoVideoId }">
        <div v-if="content.supplementaryTitle || content.title"
             ref="heroContent"
             class="c-hero-banner__content">
            <div ref="heroContentText"
                 class="w-full px-10"
                 :style="textOffset">
                <component :is="content.headerType || 'h3'"
                           :id="headerId"
                           class="c-hero-banner__heading text-center mx-auto"
                           :style="maxTitleWidth">
                    <span v-if="content.supplementaryTitle"
                          class="c-hero-banner__heading-script block font-script opacity-80 u-reverse-rotate-6 leading-md -mb-15"
                          :class="[headerFontSizes.supplementaryTitle, headerTextColor]"
                          v-html="content.supplementaryTitle"/>

                    <span v-if="content.title"
                          class="c-hero-banner__heading-bold font-brandon block leading-tight uppercase"
                          :class="[headerFontSizes.title, headerTextColor]"
                          v-html="content.title"/>
                </component>
            </div>

            <div v-if="content.link"
                 ref="heroContentLink"
                 class="c-hero-banner__btn-wrap mt-auto mb-40 md:mb-60 w-full text-center mx-10">
                <router-link-conditional :to="content.link.url"
                                         :title="content.link.name"
                                         class="c-hero-banner__btn c-btn c-btn--themed inline-flex mx-auto"
                                         @click.native="trackPromotionClick(content.link.name)">
                    <span class="c-btn__text font-bold text-12 md:text-14 tracking-normal">
                        {{ content.link.name }}
                    </span>

                    <span class="c-btn__icon">
                        <c-icon name="new-light"/>
                    </span>
                </router-link-conditional>
            </div>
        </div>

        <div v-if="vimeoVideoId"
             class="c-hero-banner__vimeo">
            <vimeo-player :key="vimeoVideoId"
                          ref="vimeo"
                          v-bind="vimeoPlayerOptions"/>
        </div>

        <div v-else-if="imageSrc"
             class="c-hero-banner__image relative z-0">
            <parallax :speed-factor="parallaxSpeedFactor"
                      :section-height="imageHeight"
                      :breakpoint="''">
                <img v-on-error
                     :src="imageSrc.x1"
                     :srcset="`${imageSrc.x1} 1x, ${imageSrc.x2} 2x`"
                     loading="eager"
                     fetchpriority="high"
                     :alt="altText">
            </parallax>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { vueVimeoPlayer as VimeoPlayer } from 'vue-vimeo-player';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

import spaStore from '@/core/spa/store/spa.store';
import breakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';

import { HeroBlockModel, Media, ParallaxScrolling } from '@/types/serverContract';

import { getHeaderAnchorLink } from '@/project/shared/string.util';
import Parallax from '@/project/thirdparty/vue-parallax-master/src/components/Parallax.vue';
import trackingUtils from '@/project/tracking/tracking.utils';

export enum ImageAspectMode {
    Square = 'square',
    Portrait = 'portrait',
    Landscape = 'landscape'
}

export type HeaderFontSizes = { supplementaryTitle: string, title: string };

Vue.use(VimeoPlayer);

const ResizeObserver = (window as any).ResizeObserver || ResizeObserverPolyfill;

@Component({
    components: {
        VimeoPlayer,
        Parallax
    }
})
export default class BlockHeroBanner extends Vue {
    @Prop() content!: HeroBlockModel;

    windowWidth = 0;
    windowHeight = 0;

    contentHeight = 0;
    contentTextHeight = 0;
    contentLinkHeight = 0;

    imageAspectMode: ImageAspectMode = ImageAspectMode.Square;

    animationFrame: number | undefined;
    observer = new ResizeObserver(() => {
        if (this.animationFrame) {
            cancelAnimationFrame(this.animationFrame);
        }

        this.animationFrame = requestAnimationFrame(() => this.setHeroStyes());
    });

    $refs!: {
        vimeo: VimeoPlayer,
        heroContent: HTMLDivElement,
        heroContentText: HTMLDivElement,
        heroContentLink: HTMLDivElement
    };

    headerFontSizes: HeaderFontSizes = this.getHeaderFontSizes();

    vimeoVideoId: string | null = null;
    vimeoPlayerOptions = {
        videoId: this.vimeoVideoId,
        playerWidth: undefined,
        playerHeight: undefined,
        options: {
            responsive: true,
            muted: true,
            loop: true,
            autoplay: true,
            background: true,
            controls: false,
            dnt: !spaStore?.cookieConsentStatus().cookieCatStatistic,
            playsinline: true
        }
    };

    get image(): Media | null {
        const { image, imageTallFormat, imageSquareFormat } = this.content;

        if (this.imageAspectMode === ImageAspectMode.Portrait && imageTallFormat) {
            return imageTallFormat;
        } else if (this.imageAspectMode === ImageAspectMode.Square && imageSquareFormat) {
            return imageSquareFormat;
        }

        return image || null;
    }

    get imageSrc(): { x1: string, x2: string } | null {
        if (!this.image) {
            return null;
        }

        const { focalPoint, url } = this.image;

        const focal = focalPoint ? `&rxy=${focalPoint.left},${focalPoint.top}` : '';

        return {
            x1: `${url}?height=${this.windowHeight}&width=${this.windowWidth}${focal}&quality=70&format=jpg&mode=crop`,
            x2: `${url}?height=${this.windowHeight * 2}&width=${this.windowWidth * 2}${focal}&quality=70&format=jpg&mode=crop`
        };
    }

    get imageHeight(): number | null {
        if (!this.image) {
            return null;
        }

        return Math.floor(Math.min(this.windowHeight, this.image.height) * 0.8);
    }

    get headerId(): string | undefined {
        return getHeaderAnchorLink(this.content);
    }

    get altText(): string | undefined {
        const { altText, supplementaryTitle, title } = this.content;

        if (altText) {
            return altText;
        } else if (supplementaryTitle) {
            return supplementaryTitle;
        } else if (title) {
            const prefix = supplementaryTitle ? `${supplementaryTitle} - ` : '';

            return `${prefix}${title}`;
        }

        return spaStore.pageData.metadata.seoTitle;
    }

    get maxTitleWidth() {
        if (!this.content.maxTitleWidth) {
            return undefined;
        }

        return { width: `${this.content.maxTitleWidth}%` };
    }

    get headerTextColor() {
        const { textColor, textColorMobile } = this.content;

        if (breakpointsState.isBreakpointActive('max-sm') && textColorMobile) {
            return textColorMobile === 'theme-1' ? 'text-theme-1' : 'text-theme-2';
        }

        return textColor === 'theme-1' ? 'text-theme-1' : 'text-theme-2';
    }

    get textOffset() {
        const { textOffset, textOffsetMobile } = this.content;
        const mobileOffset = textOffsetMobile || textOffset;
        const offset = breakpointsState.isBreakpointActive('max-sm') ? mobileOffset : textOffset;

        return {
            marginTop: `${(offset || 60) * (this.contentHeight - (this.contentTextHeight + this.contentLinkHeight)) / (100 * 10)}rem`
        };
    }

    get parallaxSpeedFactor() {
        switch (this.content.parallaxEffect) {
            case ParallaxScrolling.None:
                return 0;
            case ParallaxScrolling.Slow:
                return 0.8;
            case ParallaxScrolling.Fast:
                return 1;
            default:
                return 0.9;
        }
    }

    mounted() {
        this.$nextTick(() => {
            this.observer.observe(document.body);
            this.observer.observe(this.$el);
        });
    }

    destroyed() {
        this.observer.disconnect();
    }

    setHeroStyes(): void {
        this.windowHeight = this.getWindowHeight();
        this.windowWidth = this.getWindowWidth();

        this.imageAspectMode = this.getImageAspectMode();
        this.headerFontSizes = this.getHeaderFontSizes();
        this.contentHeight = this.$el?.clientHeight || 0;
        this.contentTextHeight = this.$refs.heroContentText?.clientHeight || 0;
        this.contentLinkHeight = this.$refs.heroContentLink?.clientHeight || 0;

        this.vimeoVideoId = this.getVimeoVideoId();
        this.vimeoPlayerOptions = {
            ...this.vimeoPlayerOptions,
            videoId: this.vimeoVideoId
        };
    }

    getWindowHeight() {
        const heightDimension = window.innerWidth < 450 ? 700 : 1400;

        return Math.ceil(Math.min(window.innerHeight, heightDimension) / 100) * 100;
    }

    getWindowWidth() {
        return Math.ceil(Math.min(window.innerWidth, 1600) / 100) * 100;
    }

    getImageAspectMode() {
        const ratio = window.innerWidth / window.innerHeight;

        if (ratio < 0.9) {
            return ImageAspectMode.Portrait;
        } else if (ratio > 1.3) {
            return ImageAspectMode.Landscape;
        }

        return ImageAspectMode.Square;
    }

    getHeaderFontSizes(): HeaderFontSizes {
        const height = Math.max(window.innerHeight, window.document.documentElement!.clientHeight);

        if (height > 790) {
            return {
                supplementaryTitle: 'text-40 ls:text-60 md:text-60',
                title: 'text-42 ls:text-70 md:text-70'
            };
        }

        return {
            supplementaryTitle: 'text-32 ls:text-40 md:text-40',
            title: 'text-34 ls:text-50 md:text-45'
        };
    }

    getVimeoVideoId() {
        const isPortrait = window.innerWidth < window.innerHeight ||
                                window.screen?.orientation?.type.includes('portrait');
        const { vimeoIdDefault, vimeoIdPortrait } = this.content;
        const videoId = isPortrait ? vimeoIdPortrait : vimeoIdDefault;

        return videoId || null;
    }

    trackPromotionClick(linkName: string) {
        trackingUtils.promotion.trackPromotionClick(
            {
                componentName: this.$options.name,
                trackingName: this.content.trackingName,
                trackingTitle: this.content.title || this.content.supplementaryTitle,
                creativeText: linkName,
                position: 0
            }
        );
    }
}
</script>

<style lang="less">
@import (reference) '../../../styling/0-Settings/index.less';

.c-hero-banner {
    @apply overflow-hidden relative;

    @screen lg {
        &.c-hero-banner__no-max {
            @apply max-h-none;
        }
    }
}

.c-hero-banner__content {
    @apply absolute inset-0 z-1 w-full h-full flex flex-wrap items-center justify-center;
}

.c-hero-banner__heading {
    @apply mb-0;

    margin-top: 6rem;

    @media @maxTitleWidthOverwriteMediaQuery {
        width: auto !important;
    }

    @screen lg {
        margin-top: 4.1rem;
    }
}

.c-hero-banner__heading-script {
    letter-spacing: 2.02px;
    margin-bottom: -0.9rem;
    margin-left: 0.5rem;

    @screen lg {
        letter-spacing: 0.12px;
        padding-top: .8rem;
        margin-bottom: -2.6rem;
        margin-left: -1.2rem;
    }
}

.c-hero-banner__heading-bold {
    letter-spacing: -0.5px;

    @screen lg {
        letter-spacing: -0.5px;
        line-height: .9;
    }
}

.c-hero-banner__btn-wrap {
    margin-bottom: 3.9rem;

    @screen lg {
        margin-bottom: 5.9rem;
    }

    .c-btn__text {
        letter-spacing: -.4px;
    }
}

.c-hero-banner__vimeo {
    iframe {
        @apply w-full;
    }
}

.c-hero-banner__image {
    .Masthead .is-parallax {
        @apply bottom-0;
    }
}
</style>
