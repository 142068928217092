<template>
    <div class="overflow-y-hidden ml-40 flex">
        <ul v-if="headerNavigation"
            class="truncate ml-auto flex h-60 w-full">
            <template v-for="(link, ix) in headerNavigation.links">
                <li
                    v-if="
                        navigationService.isStandardLink(link.type) &&
                            link.nameAndUrl &&
                            link.nameAndUrl.url
                    "
                    :key="ix"
                    class="relative h-full px-10">
                    <router-link
                        :to="link.nameAndUrl.url"
                        :title="link.nameAndUrl.name"
                        class="desktop-nav-link">
                        <span class="desktop-nav-link-text">
                            {{ link.nameAndUrl.name }}
                        </span>
                    </router-link>
                </li>
                <li
                    v-else-if="
                        navigationService.isProductsLink(link.type) &&
                            link.nameAndUrl &&
                            link.nameAndUrl.url
                    "
                    :key="ix"
                    class="relative h-full px-10">
                    <router-link
                        :to="link.nameAndUrl.url"
                        :title="link.nameAndUrl.name"
                        class="desktop-nav-link"
                        event
                        @click.native.prevent="productsSelected()">
                        <span class="desktop-nav-link-text">
                            {{ link.nameAndUrl.name }}
                        </span>
                    </router-link>
                </li>
                <li
                    v-else-if="
                        navigationService.isRoomsLink(link.type) &&
                            link.nameAndUrl &&
                            link.nameAndUrl.url
                    "
                    :key="ix"
                    class="relative h-full px-10">
                    <router-link
                        :to="link.nameAndUrl.url"
                        :title="link.nameAndUrl.name"
                        class="desktop-nav-link"
                        event
                        @click.native.prevent="roomsSelected()">
                        <span class="desktop-nav-link-text">
                            {{ link.nameAndUrl.name }}
                        </span>
                    </router-link>
                </li>
            </template>
            <li
                v-if="headerNavigation.seasonalLink && headerNavigation.seasonalLink.url"
                class="relative h-full px-10">
                <router-link
                    :to="headerNavigation.seasonalLink.url"
                    :title="headerNavigation.seasonalLink.name"
                    class="desktop-nav-link">
                    <span class="desktop-nav-link-text">
                        {{ headerNavigation.seasonalLink.name }}
                    </span>
                </router-link>
            </li>
        </ul>
        <nav class="hidden">
            <!-- Links are only for SEO discovery (and accessibility) of stuff that will only be visible from Portal Overlay content -->
            <router-link v-for="(seoLink, ix) in seoLinks"
                         :key="ix"
                         :to="seoLink.url">
                {{ seoLink.name }}
            </router-link>
        </nav>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import useHeaderNavigation from './useHeaderNavigation';
import navigationService from '@/project/shared/navigation/navigation.service';

const { headerNavigation } = useHeaderNavigation();
const emit = defineEmits(['products-selected', 'rooms-selected', 'toggle-search', 'toggle-basket']);

const productsSelected = () => {
    emit('products-selected');
};

const roomsSelected = () => {
    emit('rooms-selected');
};

const seoLinks = computed(() => {
    return [
        ...(navigationService.getProductsLink()?.subNavigationLinks.map((l) => l.nameAndUrl) ?? []),
        ...(navigationService.getRoomsLink()?.subNavigationLinks.map((l) => l.nameAndUrl) ?? [])
    ];
});

</script>

<style lang="less">
.desktop-nav-link {
    @apply text-brown-80 uppercase text-13 font-brandon font-medium text-current h-full inline-flex items-center;
    transition: color .3s ease, transform .3s ease;
    letter-spacing: .54px;

    &::before {
        @apply bg-current absolute;
        transition: height .25s ease;
        top: 0;
        left: theme('padding.10');
        right: theme('padding.10');
        content: '';
        display: block;
        height: 0;
    }

    &:hover,
    &.router-link-exact-active {
        &::before {
            height: 0.4rem;
        }
    }
}

.desktop-nav-link-text {
    @apply relative top-2;
}

</style>