import { SpaPageRenderedEventKey } from '@/core/spa/router';
import bus from '@/core/bus';
import spaStore from '@/core/spa/store/spa.store';
import { doDataLayerPush } from '@/project/tracking/googleTagManager.utils';
import { isPrerenderUseragent } from '@/project/shared/string.util';
import userStore from '@/store/user.store';

// Only fire on SPA load, not "true" page loads (that is handled with 'originalVariables' elsewhere)
bus.once(SpaPageRenderedEventKey, async() => {
    bus.on(SpaPageRenderedEventKey, () => {
        doDataLayerPush({
            event: 'Variables',
            LoggedInState: userStore.isLoggedIn ? 'LoggedIn' : 'LoggedOut',
            pageType: spaStore.jsonContent && spaStore.jsonContent.alias,
            NewsletterSignedUp: userStore.isLoggedIn ? userStore.userInformation?.newsletterSignup ? 'True' : 'False' : 'Anonymous'
        });
    });
});
if (isPrerenderUseragent()) {
    document.body.classList.add('prerender-is-active');
}
