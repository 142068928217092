import Vue, { VNodeDirective } from 'vue';
import $ from 'cash-dom';
import constants from '@/project/config/constants';
import { isPrerenderUseragent } from '@/project/shared/string.util';

function errorHandler(el:HTMLElement, classNamesArray:string[] = []): void {
    el.setAttribute('src', constants.NoImageImgSrcBase64);
    el.removeAttribute('srcset');

    Array.from(el.parentNode?.children ?? [])
        .filter(c => c.tagName.toLowerCase() === 'source')
        .forEach(c => c.remove());

    classNamesArray.forEach((className: string) => {
        el.classList.add(className);
        const closestParent = el.closest('.responsive-image');
        if (closestParent) {
            closestParent.classList.add('responsive-image--no-image');
        }
    });
}

Vue.directive('on-error', {
    bind: function(el: HTMLElement, binding: VNodeDirective) {
        if (isPrerenderUseragent()) return;
        const classNamesArrayTemp = binding.value && binding.value[0] ? binding.value : [];
        const classNamesArray = ['c-on-error', ...classNamesArrayTemp];

        el.addEventListener('error', () => errorHandler(el, classNamesArray));
    }
});

Vue.directive('delegateOnImgError', {
    bind: function(elWrapper: HTMLElement, binding: VNodeDirective) {
        if (isPrerenderUseragent()) return;
        const imageEls = $(elWrapper).find('img');

        const classNamesArrayTemp = binding.value && binding.value[0] ? binding.value : [];
        const classNamesArray = ['c-on-error', ...classNamesArrayTemp];

        imageEls.each((index, el) => {
            const tempEl = el as HTMLElement;
            tempEl.addEventListener('error', () => errorHandler(el, classNamesArray));
        });
    }
});
