<template>
    <div v-if="options"
         class="border-b border-t border-white-200 pt-20 pb-10">
        <div>
            <div class="uppercase mb-10 text-10 tracking-medium-heading">
                <span>{{ $translate('search.Filters.Sort') }}</span>
            </div>
        </div>
        <slot>
            <ul class="flex flex-wrap -ml-10">
                <li v-for="option in options"
                    :key="option.id"
                    class="w-1/2 pl-10 pb-10"
                    :title="$translate(option.label)">
                    <button type="button"
                            class="w-full pt-10 pb-6 relative font-light text-12 leading-sm uppercase"
                            :aria-label="$translate(option.label)"
                            :title="$translate(option.label)"
                            :class="{'bg-white-550':activeKey === option.id, 'bg-white-300':activeKey !== option.id}"
                            @click="handleClickFacet(options, option)">
                        <span>
                            {{ $translate(option.label ) }}
                        </span>
                    </button>
                </li>
            </ul>
        </slot>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import urlHelper from '../facets/urlHelper.service';
import dictionary from '@/core/translation/dictionary.service';
import trackingUtils from '@/project/tracking/tracking.utils';
@Component
export default class SearchSortingButtons extends Vue {
    activeSorting: string | null = null;

    @Prop({
        type: String,
        required: true
    })
        activeKey!: string;

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
        isSearchResults!: boolean;

    options = [
        {
            id: 'recent',
            label: 'search.Sorting.Recent'
        },
        {
            id: 'popularity',
            label: 'search.Sorting.Popularity'
        },
        {
            id: 'priceDesc',
            label: 'search.Sorting.PriceDescending'
        },
        {
            id: 'priceAsc',
            label: 'search.Sorting.PriceAscending'
        }];

    get activeDisplayName(): string {
        if (!this.activeKey) return ''; // initially empty, until we get answer from server
        const activeChoice = this.options.find(o => o.id === this.activeKey);
        if (!activeChoice) throw new Error('Got unknown sort-key: ' + this.activeKey);
        return dictionary.get(activeChoice.label);
    }

    handleClickFacet(facetGroup: { id: string, label: string }[], facet: { id: string, label: string }) {
        this.onChange(facet.id);
    }

    onChange(event: string) {
        urlHelper.setSortBy(event);
        trackingUtils.facetFilter.trackSortingChange(event, this.activeKey);
    }
}
</script>

<style lang="less" scoped>
</style>
