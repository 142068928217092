import { pageResolver } from '@/core/spa/componentResolver.service';

import DefaultPage from './DefaultPage.vue';
import ProductDetailsPage from './ProductDetailsPage.vue';
import CategoryDetailsPage from './CategoryDetailsPage.vue';
import PageNotFound404Page from '@/project/spa/pages/PageNotFound404Page.vue';
const ChangePasswordPage = () => import('@/project/spa/pages/ChangePasswordPage.vue');
const MyPagePage = () => import('@/project/spa/pages/MyPagePage.vue');
const MyPersonalInformationPage = () => import('@/project/spa/pages/MyPersonalInformationPage.vue');
const MyFavoriteProductsPage = () => import('@/project/spa/pages/MyFavoriteProductsPage.vue');
const MyFavoriteContentPage = () => import('@/project/spa/pages/MyFavoriteContentPage.vue');
const MyOrdersPage = () => import('@/project/spa/pages/MyOrdersPage.vue');
const MyOrderDetailsPage = () => import('@/project/spa/pages/MyOrderDetailsPage.vue');
const ExternalAccountPage = () => import('@/project/spa/pages/ExternalAccountPage.vue');
const CheckoutPage = () => import('@/project/spa/pages/CheckoutPage.vue');
const ReceiptPage = () => import('@/project/spa/pages/ReceiptPage.vue');
const TopicDetailsPage = () => import('./TopicDetailsPage.vue');
const UserOnboardingPage = () => import('./UserOnboardingPage.vue');
const FaqPage = () => import('./FaqPage.vue');
const CategoryListPage = () => import('./CategoryListPage.vue');
const SearchResultCatalogPage = () => import('./SearchResultCatalogPage.vue');
const SearchResultBatchedSearch = () => import('./SearchResultBatchedSearch.vue');
const SearchResultMomentsPage = () => import('./SearchResultMomentsPage.vue');
const StoreListPage = () => import('@/project/spa/pages/StoreListPage.vue');
const StoreDetailsPage = () => import('@/project/spa/pages/StoreDetailsPage.vue');
const CookiePolicyPage = () => import('./CookiePolicyPage.vue');
const ArticlePage = () => import('./ArticlePage.vue');
const InspirationListPage = () => import('./InspirationListPage.vue');
const DiyListPage = () => import('./DiyListPage.vue');
const DiyDetailsPage = () => import('@/project/spa/pages/DiyDetailsPage.vue');
const SharedFavoriteProductsPage = () => import('@/project/spa/pages/SharedFavoriteProductsPage.vue');
const RoomDetailsPage = () => import('@/project/spa/pages/RoomDetailsPage.vue');
const RoomListPage = () => import('@/project/spa/pages/RoomListPage.vue');
const LegalPage = () => import('@/project/spa/pages/LegalPage.vue');
const MomentFrontPage = () => import('@/project/spa/pages/MomentFrontPage.vue');
const MomentDetailsPage = () => import('@/project/spa/pages/MomentDetailsPage.vue');
const MomentListPage = () => import('@/project/spa/pages/MomentListPage.vue');
const MomentThemeDetails = () => import('@/project/spa/pages/MomentThemeDetails.vue');
const HowToListPage = () => import('@/project/spa/pages/HowToListPage.vue');
const MomentInspirationalPage = () => import('@/project/spa/pages/MomentInspirationalPage.vue');

pageResolver.registerFallback(DefaultPage);
pageResolver.register('home', DefaultPage);
pageResolver.register('article', ArticlePage);
pageResolver.register('productDetails', ProductDetailsPage);
pageResolver.register('categoryDetails', CategoryDetailsPage);
pageResolver.register('brandPage', CategoryDetailsPage);
pageResolver.register('roomDetails', RoomDetailsPage);
pageResolver.register('categoryList', CategoryListPage);
pageResolver.register('topic', TopicDetailsPage);
pageResolver.register('userOnboarding', UserOnboardingPage);
pageResolver.register('faq', FaqPage);
pageResolver.register('diyList', DiyListPage);
pageResolver.register('inspirationList', InspirationListPage);
pageResolver.register('diyDetails', DiyDetailsPage);
pageResolver.register('searchResultCatalog', SearchResultCatalogPage);
pageResolver.register('searchResultMoments', SearchResultMomentsPage);
pageResolver.register('searchResultBatchedSearch', SearchResultBatchedSearch);
pageResolver.register('storeList', StoreListPage);
pageResolver.register('storeDetails', StoreDetailsPage);
pageResolver.register('pageNotFound', PageNotFound404Page);
pageResolver.register('myPage', MyPagePage);
pageResolver.register('myFavoriteProducts', MyFavoriteProductsPage);
pageResolver.register('myFavoriteContent', MyFavoriteContentPage);
pageResolver.register('myOrders', MyOrdersPage);
pageResolver.register('myOrderDetails', MyOrderDetailsPage);
pageResolver.register('sharedFavorites', SharedFavoriteProductsPage);
pageResolver.register('cookiePolicy', CookiePolicyPage);
pageResolver.register('checkout', CheckoutPage);
pageResolver.register('receipt', ReceiptPage);
pageResolver.register('changePassword', ChangePasswordPage);
pageResolver.register('myPersonalInformation', MyPersonalInformationPage);
pageResolver.register('externalAccount', ExternalAccountPage);
pageResolver.register('momentFrontpage', MomentFrontPage);
pageResolver.register('momentDetails', MomentDetailsPage);
pageResolver.register('roomList', RoomListPage);
pageResolver.register('legalPage', LegalPage);
pageResolver.register('momentList', MomentListPage);
pageResolver.register('howToList', HowToListPage);
pageResolver.register('themeDetails', MomentThemeDetails);
pageResolver.register('momentInspirationalPage', MomentInspirationalPage);
