<template>
    <form novalidate
          v-bind="$attrs"
          @submit.prevent="handleSubmit">
        <slot/>
    </form>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ErrorBag } from 'vee-validate';
import ScrollService from '@/core/scroll/scroll.service';

@Component
export default class Form extends Vue {
    @Prop({
        type: Function
    })
        submit!: (payload: Record<string, unknown>) => void;

    @Prop({
        type: Object
    })
        componentRefs!: any;

    // From Vue-validate
    vvErrors!: ErrorBag;
    vvFields!: any;

    scrollToFirstError() {
        const firstError = this.vvErrors.items[0];
        const { field } = firstError;

        const componentRef = this.componentRefs[field];
        if (componentRef) {
            let input: HTMLInputElement | HTMLButtonElement | undefined;

            // look for the native HTML element in the Vue Component (ref=input)
            // eg. input type="radio" componentRef is an array
            if (Array.isArray(componentRef)) {
                input = componentRef[0].$refs?.input;
            } else {
                input = componentRef.$refs?.input;
            }

            // if we dont have an `input` now, try to get the componentRef itself
            // adds support for passing HTML elements directly to the componentRef prop
            if (!input) {
                input = componentRef;
            }

            if (input && input.name === field) {
                ScrollService.scrollInToView(input);
            }
        }
    }

    get errorBag() {
        return this.vvErrors;
    }

    async handleSubmit() {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
            this.scrollToFirstError();
        }

        this.submit({
            isValid,
            vvErrors: this.vvErrors,
            vvFields: this.vvFields
        });
    }
}
</script>
