<template>
    <div
        class="c-brand relative left-1/2 right-1/2 -mx-1/2-screen flex w-screen bg-sand-20 py-32 md:inset-x-auto md:mx-0 md:w-full">
        <div class="mx-auto flex max-w-390 flex-col items-center gap-32 px-40 text-center md:max-w-350 md:px-24">
            <div v-if="content.title"
                 class="font-brandon text-14 font-medium uppercase leading-16">
                {{ content.title }}
            </div>
            <img v-if="fallback"
                 :src="fallback"
                 :srcset="srcSet"
                 :sizes="sizes"
                 :alt="content.name">
            <RouterLinkConditional
                v-if="isUrlLink(content.link)"
                :to="content.link.url"
                class="leading-0 font-brandon text-12 font-medium uppercase tracking-0.6 underline hover:no-underline">
                {{ content.link.text }}
            </RouterLinkConditional>
            <div v-if="content.note"
                 class="font-gibson text-14 leading-20">
                {{ content.note }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { v4 } from '@/types/serverContract';
import { isUrlLink } from '@/project/shared/link/linkUtil';
const props = defineProps<{
    content: v4.Products.Brand;
}>();

const imageHeight = ref<number | null>(null);
const imageWidth = ref<number | null>(null);
const imageSourceMap = ref<{
    '1x': string;
    '2x': string;
    '3x': string;
} | null>(null);

const sizes = computed(() => {
    if (!imageWidth.value) {
        return undefined;
    }

    return `${imageWidth.value}px`;
});

const fallback = computed(() => {
    if (!imageSourceMap.value) {
        return null;
    }

    return imageSourceMap.value['1x'];
});

const srcSet = computed(() => {
    if (!imageSourceMap.value) {
        return undefined;
    }

    return `${imageSourceMap.value['1x']}, ${imageSourceMap.value['2x']} 2x, ${imageSourceMap.value['3x']} 3x`;
});

watch(
    () => props.content.media,
    (media) => {
        if (!media) {
            return;
        }

        const { url, height, width } = media;

        imageHeight.value = 40;
        imageHeight.value = Math.floor(height / 4);
        if (props.content.iconDisplayHeight) {
            imageHeight.value = props.content.iconDisplayHeight;
        }

        imageWidth.value = Math.floor((width / height) * imageHeight.value);

        const params = new URLSearchParams();
        params.set('height', imageHeight.value.toString());
        params.set('format', 'webp');

        const params2x = new URLSearchParams(params);
        params2x.set('height', (imageHeight.value * 2).toString());

        const params3x = new URLSearchParams(params);
        params3x.set('height', (imageHeight.value * 3).toString());

        imageSourceMap.value = {
            '1x': `${url}?${params.toString()}`,
            '2x': `${url}?${params2x.toString()}`,
            '3x': `${url}?${params3x.toString()}`
        };
    },
    { immediate: true, deep: true }
);
</script>
