<template>
    <div>
        <div class="pt-24 px-20">
            <RouterLink :to="{ path: searchPage, query: { term: searchTerm } }"
                        class="exact-term relative z-1 transition duration-300 pl-8 items-center mb-32 flex">
                <cIcon class="mr-12 self-center bg-transparent"
                       name="search"
                       width="14"/>
                <span class="text-16 font-gibson-light leading-14">
                    {{ searchTerm }}
                </span>
            </RouterLink>
        </div>
        <div class="suggestions-max-height wrap">
            <SplitTest feature-key="Fsst-search_suggestions_category_suggestion"
                       default-variation="default"
                       is-fallback>
                <Transition
                    mode="out-in"
                    leave-active-class="animated fadeOut u-anim-dur-400"
                    enter-active-class="animated fadeIn u-anim-dur-400">
                    <div v-if="predictions?.length || categories?.length">
                        <div>
                            <div class="sticky top-0 bg-sand-10 text-14 px-8 font-gibson-regular border-b border-bottom-navigation-line pb-12">
                                {{ $translate('Search.Overlay.Suggestions') }}
                            </div>
                            <RouterLink v-for="(item, i) in predictions"
                                        :key="i"
                                        :to="item.url"
                                        class="pt-22 px-8 pb-12 w-full font-gibson-light leading-14 border-b border-bottom-navigation-line block capitalize-first transition duration-300 hover:bg-sand-20"
                                        :class="{'blur': suggestionsState === PendingState.Pending}"
                                        @click.native="handleSuggestionClick(item.term)">
                                {{ item.term }}
                            </RouterLink>
                        </div>
                    </div>
                    <div v-else-if="suggestionsState === PendingState.Resolved">
                        <SearchNoResultHeader
                            :term="searchTerm"
                            class="pt-8 pb-16 px-16"/>
                    </div>
                </Transition>
            </SplitTest>
            <SplitTest feature-key="Fsst-search_suggestions_category_suggestion"
                       default-variation="default"
                       variation="suggestions_category">
                <Transition
                    mode="out-in"
                    leave-active-class="animated fadeOut u-anim-dur-400"
                    enter-active-class="animated fadeIn u-anim-dur-400">
                    <div v-if="predictions?.length || categories?.length">
                        <div>
                            <div class="sticky top-0 bg-sand-10 text-14 px-8 font-gibson-regular border-b border-bottom-navigation-line pb-12">
                                {{ $translate('Search.Overlay.Suggestions') }}
                            </div>
                            <RouterLink v-for="(item, i) in predictions"
                                        :key="i"
                                        :to="item.url"
                                        class="pt-22 px-8 pb-12 w-full font-gibson-light leading-14 border-b border-bottom-navigation-line block capitalize-first transition duration-300 hover:bg-sand-20"
                                        :class="{'blur': suggestionsState === PendingState.Pending}"
                                        @click.native="handleSuggestionClick(item.term)">
                                {{ item.term }}
                            </RouterLink>
                        </div>
                        <div v-if="categories?.length"
                             class="pt-40">
                            <div class="sticky top-0 bg-sand-10 text-14 px-8 font-gibson-regular border-b border-bottom-navigation-line pb-12">
                                {{ $translate('Search.Overlay.ProductCategories') }}
                            </div>
                            <RouterLink v-for="item in categories" 
                                        :key="item.categoryId" 
                                        :to="item.url" 
                                        class="pt-22 px-8 pb-12 w-full font-gibson-light leading-14 border-b border-bottom-navigation-line block capitalize-first transition duration-300 hover:bg-sand-20"
                                        :class="{'blur': suggestionsState === PendingState.Pending}"
                                        @click.native="handleCategoryClick(item.displayName)">
                                {{ item.displayName }}
                            </RouterLink>
                        </div>
                    </div>
                    <div v-else-if="suggestionsState === PendingState.Resolved">
                        <SearchNoResultHeader
                            :term="searchTerm"
                            class="pt-8 pb-16 px-16"/>
                    </div>
                </Transition>
            </SplitTest>            
        </div>
    </div>
</template>

<script lang="ts" setup>
import useSearch from '@/project/search/useSearch';
import { computed } from 'vue';
import serverContext from '@/core/serverContext.service';
import { PendingState } from '@/types/frontend';
import SearchNoResultHeader from '../SearchNoResultHeader.vue';
import trackingUtils from '@/project/tracking/tracking.utils';
import SplitTest from '@/project/growthbook/SplitTest.vue';

const searchPage = serverContext.searchContext.batchSearchResultUrl;

const { searchTerm, searchSuggestionsResponse, handleNavigation, suggestionsState, closeOverlay } = useSearch();

const handleSuggestionClick = (term: string)=> {
    handleNavigation(term);
    trackingUtils.search.trackQuickLinkClick(term, 'term_suggestion');
};

const handleCategoryClick = (categoryName: string)=> {
    closeOverlay();
    trackingUtils.search.trackQuickLinkClick(categoryName, 'category_suggestion');
};

const predictions = computed(()=> {
    return searchSuggestionsResponse.value?.predictions;
});

const categories = computed(()=> {
    return searchSuggestionsResponse.value?.categoryHits;
});
</script>

<style scoped lang="less">
.exact-term {
    &::before {
        content: '';
        @apply transition duration-300;
    }
    &:hover::before {
        @apply bg-sand-20 absolute inset-0 -mt-15 -mb-15 -z-1;
    }
}

.blur {
    filter: blur(3px);
}
</style>
