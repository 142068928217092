<template>
    <ProductDetailsColorVariant v-if="colorVariantType"
                                :details="details"
                                :selected-variant="selectedVariant"/>
    <ProductDetailsTextVariant v-else-if="TextVariantType"
                               :details="details"
                               :selected-variant="selectedVariant"/>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent } from 'vue';
import { v4 } from '@/types/serverContract';
const ProductDetailsColorVariant = defineAsyncComponent(() => import('@/project/product/details/ProductDetailsColorVariant.vue'));
const ProductDetailsTextVariant = defineAsyncComponent(() => import('@/project/product/details/ProductDetailsTextVariant.vue'));

const props = defineProps<{
    details: v4.Products.ProductDetails;
    selectedVariant: v4.Products.ProductDetailsVariant;
}>();

const colorVariantType = computed(() => {
    return props.details.variantInfo?.type === v4.Products.GenericVariantType.Color;
});

const TextVariantType = computed(() => {
    return props.details.variantInfo?.type === v4.Products.GenericVariantType.Text;
});

</script>
