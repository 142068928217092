<template>
    <ul v-if="paymentMethodLogoUrls"
        class="flex flex-wrap print:hidden"
        :class="{
            'gap-12': context === 'PageFooter',
            'gap-18': context === 'Checkout'
        }">
        <li v-for="(paymentMethod, index) in paymentMethodLogoUrls"
            :key="index"
            class="w-24">
            <img :src="paymentMethod.url"
                 :alt="paymentMethod.label"
                 class="rounded-5">
        </li>
    </ul>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import basketStore from '@/store/basket.store';

export default defineComponent({
    name: 'PaymentMethodIcons',
    props: {
        context: {
            type: String as () => 'PageFooter' | 'Checkout',
            required: true
        }
    },
    setup() {
        const paymentMethodLogoUrls = computed(() => basketStore.paymentMethodLogoUrls);

        return {
            paymentMethodLogoUrls
        };
    }
});
</script>
