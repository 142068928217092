<template>
    <div ref="searchInputWrapRef"
         class="relative">
        <SearchInput/>
        <Transition
            leave-active-class="animated fadeOut u-anim-dur-200"
            enter-active-class="animated fadeIn u-anim-dur-200">
            <div v-if="searchActive"
                 class="absolute left-0 right-0"
                 :style="styles">
                <SearchSuggestionsOverlay/>
            </div>
        </Transition>
        <Portal to="blind">
            <Transition
                leave-active-class="animated fadeOut u-anim-dur-200"
                enter-active-class="animated fadeIn u-anim-dur-200">
                <div v-if="searchActive">
                    <PageBlind class-names="fixed z-1 inset-0"
                               :click-handler="closeOverlay"/>
                </div>
            </Transition>
        </Portal>
    </div>
</template>

<script lang="ts" setup>
import SearchSuggestionsOverlay from '@/project/search/search-suggest/SearchSuggestionsOverlay.vue';
import useSearch from '@/project/search/useSearch';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import PageBlind from '../layout/page-blind/PageBlind.vue';
import SearchInput from './SearchInput.vue';

const searchInputWrapRef = ref<HTMLElement | null>(null);
const searchInputWidth = ref<undefined | number>(undefined);
const minSearchSuggestionsWidth = 390;

const { searchActive, closeOverlay } = useSearch();

onMounted(() => {
    window.addEventListener('resize', setInputFieldWidth);
});

const placementValue = computed<string | number>(()=> {
    if (!searchInputWidth.value || searchInputWidth.value > minSearchSuggestionsWidth) {
        return 'auto';
    }

    const val = (minSearchSuggestionsWidth - searchInputWidth.value) / 2;
    return Math.round(val);
});

const styles = computed(()=> {
    const val = typeof placementValue.value === 'string' ? placementValue.value : `-${placementValue.value}px`;
    return {
        marginLeft: val,
        marginRight: val,
    };
});

const setInputFieldWidth = ()=> {
    searchInputWidth.value = searchInputWrapRef.value?.getBoundingClientRect().width;
};

onUnmounted(() => {
    window.removeEventListener('resize', setInputFieldWidth);
});

watch(searchActive, (newVal)=> {
    if (newVal) {
        setInputFieldWidth();
    }
});

</script>

<style lang="less" scoped>
.search-input {
    @apply font-medium appearance-none;
    line-height: normal;
    // To align placeholder with input value, use string value and not pixel value for inputs https://stackoverflow.com/a/17681447 &::-webkit-search-cancel-button
    -webkit-appearance: none;
    /* purgecss ignore */
    &::-ms-clear {
        margin-right: 2rem;
    }
    &::placeholder {
        @apply font-normal text-brown-80 uppercase;
    }
    /* purgecss ignore */
    &::-webkit-input-placeholder {
        @apply font-normal text-brown-80 uppercase;
    }
    /* purgecss ignore */
    &::-moz-placeholder {
        @apply font-normal text-brown-80 uppercase;
    }
    /* purgecss ignore */
    &::-ms-input-placeholder {
        @apply font-normal text-brown-80 uppercase;
    }

    &.--full-placeholder-opacity {
        &::placeholder {
            @apply opacity-100;
        }
        &::-webkit-input-placeholder {
            @apply opacity-100;
        }
        /* purgecss ignore */
        &::-moz-placeholder {
            @apply opacity-100;
        }
        /* purgecss ignore */
        &::-ms-input-placeholder {
            @apply opacity-100;
        }
    }
}
</style>