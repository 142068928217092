<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import productsStore from '@/store/products/products.store';
import every from 'lodash-es/every';
import { v4 } from '@/types/serverContract';

@Component({
    name: 'ProductProvider'
})
export default class ProductProvider extends Vue {
    @Prop({
        type: String
    }) productId!: string;

    @Prop({
        type: Array
    }) productIds!: string[];

    get normalizedProductIds(): string[] {
        if (this.productId != null) {
            return [this.productId];
        } else if (this.productIds) {
            return this.productIds;
        }
        return [];
    }

    created() {
        productsStore.ensureLoaded(this.normalizedProductIds);
    }

    get productsByIds(): (v4.Products.ProductSimple | undefined)[] {
        return this.normalizedProductIds.
            map(productId => productsStore.getProduct(productId)).
            filter(product => product !== null) as (v4.Products.ProductSimple | undefined)[];  // Products tried to be fetched but not found are null
    }

    get product(): v4.Products.ProductSimple | null | undefined {
        return this.productsByIds[0];
    }

    get isProductsLoaded(): boolean {
        return every(Object.values(this.productsByIds), val => val !== undefined);
    }

    render() {
        return this.$scopedSlots.default!({
            product: this.product,
            products: this.productsByIds,
            productsLoaded: this.isProductsLoaded
        });
    }
}
</script>
