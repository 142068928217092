// Depends on JSNLog which is probably loaded by serverside scripting.

import Vue from 'vue';
import StackTrace from 'stacktrace-js';
import logging from './logging.service';
import serverContext from '@/core/serverContext.service';
import { isFacebookInAppUseragent } from '@/project/shared/string.util';

function init() {
    Vue.config.errorHandler = function(err, vm, info) {
        if (isProdMode()) {
            if (serverContext.frontendLogging.vueErrorHandler) {
                StackTrace.fromError(err)
                    .then(stackframes => logStackframes(stackframes, err, info))
                    .catch(logging.error);
            }
        } else {
            logging.error(err);
        }
    };

    window.onerror = function(errorMsg, url, lineNumber, column, errorObj) {
        if (isProdMode() && errorObj) {
            if (serverContext.frontendLogging.onerror) {
                StackTrace.fromError(errorObj)
                    .then(stackframes => logStackframes(stackframes, errorObj, errorMsg, url, lineNumber, column))
                    .catch(logging.error);
            }
            return true;
        } else {
            logging.error(errorObj);
        }
    };

    window.onunhandledrejection = function(event) {
        if (event && event.reason) {
            if (isProdMode()) {
                if (serverContext.frontendLogging.onunhandledrejection) {
                    StackTrace.fromError(event.reason)
                        .then(stackframes => logStackframes(stackframes, event.reason.message, event.reason))
                        .catch(() => logging.error(event.reason));
                }
            } else {
                logging.error(event);
            }
        }
    };

    function logStackframes(stackframes, errorObj, errorMsg, url?, lineNumber?, column?) {
        const stringifiedStack = stackframes
            .map(sf => {
                return sf.toString();
            })
            .join('\n');

        logging.exception(
            {
                msg: 'Exception! ' + stringifiedStack,
                errorMsg: errorMsg,
                url: url,
                lineNumber: lineNumber,
                column: column
            },
            errorObj
        );
    }

    function isProdMode() {
        return import.meta.env.NODE_ENV === 'production';
    }

    facebookBrowserBreakpointDebug(1);
    setTimeout(() => { facebookBrowserBreakpointDebug(2); }, 3000);
    function facebookBrowserBreakpointDebug(delta) {
        if (!isFacebookInAppUseragent()) return;
        // Temp logging for FB in App iOS issue to Fix instagram window.innerWidth issue https://github.community/t/instagram-in-app-browser-loading-site-incorrectly/128373/10
        logging.error(`Client screen size debug ${delta}. screen.width: ${window!.screen!.width} innerWidth:${window!.innerWidth} document.clientWidth ${window!.document!.documentElement!.clientWidth}`);
    }
}

init();
