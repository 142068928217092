<template>
    <span class="mt-4 block text-12 font-gibson-light">
        <span v-for="(part, index) in parts"
              :key="index">
            <span v-if="part.type === 'text'"
                  v-html="part.value"></span>
            <component
                :is="part.value.component"
                v-else
                :label="part.value.data.label"
                :title="part.value.data.title"
                :text="part.value.data.text"/>
        </span>
    </span>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import CheckoutTermsModal from '@/project/checkout/CheckoutTermsModal.vue';
import serverContext from '@/core/serverContext.service';
import translateFilter from '@/core/translation/translate.filter';
import { newLineToBr } from '../shared/string.util';

const props = withDefaults(defineProps<{
    labelKey?: string;
}>(), {
    labelKey: 'checkout.Terms.AcceptDescription'
});

const legalTexts = computed(() => {
    return serverContext.legalTexts;
});

const parts = computed(() => {
    const componentMap = [
        {
            component: CheckoutTermsModal,
            data: {
                label: legalTexts.value.termsOfTradeTitle,
                title: legalTexts.value.termsOfTradeTitle,
                text: legalTexts.value.termsOfTradeText
            }
        },
        {
            component: CheckoutTermsModal,
            data: {
                label: legalTexts.value.privacyPolicyTitle,
                title: legalTexts.value.privacyPolicyTitle,
                text: legalTexts.value.privacyPolicyText
            }
        }
    ];

    const input = newLineToBr(translateFilter(props.labelKey));
    const regex = /{(\d+)}/g;
    let matches: RegExpExecArray | null;
    let lastIndex = 0;
    const result: Array<{
        type: 'text';
        value: string;
    } | {
        type: 'component';
        value: (typeof componentMap)[number];
    }> = [];

    // setting up `result` array with text and component entries,
    // loop over translation and replace placeholders with matching vue components from `componentMap`
    while ((matches = regex.exec(input))) {
        const index = parseInt(matches[0].slice(1, -1));
        const textBefore = input.slice(lastIndex, matches.index);
        if (textBefore) {
            result.push({ type: 'text', value: textBefore });
        }
        result.push({ type: 'component', value: componentMap[index] });
        lastIndex = regex.lastIndex;
    }

    const textAfter = input.slice(lastIndex);
    if (textAfter) {
        result.push({ type: 'text', value: textAfter });
    }

    return result;
});
</script>
