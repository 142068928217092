<template>
    <Totals
        :num-products="itemCount || 0"
        :price="totalInclVat || 0"
        :shipping-price="shippingPrice"
        :exact-shipping-price="isShippingPriceExact"
        :total-incl-shipping="totalInclShipping"/>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import Totals from '@/project/checkout/Totals.vue';
import basketStore from '@/store/basket.store';
import { checkoutStore } from '@/store/checkout.store';

const itemCount = computed(() => basketStore.itemCount);
const shippingPrice = computed(() => {
    if (basketStore.clientSelectedShippingMethod) {
        return basketStore.clientSelectedShippingMethod.price;
    }

    return basketStore.estimatedShippingPrice;
});
const totalInclVat = computed(() => basketStore.basket?.totalInclVat);
const totalInclShipping = computed(() => {
    if (shippingPrice.value == null || totalInclVat.value === undefined) {
        // return null results in "Calculated later"
        return null;
    }
    return shippingPrice.value + totalInclVat.value;
});

const isShippingPriceExact = computed(() => checkoutStore.selectedShippingMethod !== null);

</script>
