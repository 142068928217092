<template>
    <ul>
        <li v-for="(slide, i) in slides"
            :key="i"
            class="ml-4 inline-block">
            <button class="u-trans u-trans-transform hover-style"
                    @click="goTo(i)">
                <cIcon name="dot"
                       class="w-4 h-4 text-brown-80 opacity-25 u-trans u-trans-transform"
                       :class="[{'active-slide': activeIndex === i}]"/>
            </button>
        </li>
    </ul>
</template>

<script setup lang="ts">

defineProps<{
    slides: number[] | number;
    activeIndex: number;
    increaseSelectedSize?: boolean;
}>();

const emit = defineEmits(['go-to']);

const goTo = (i) => {
    emit('go-to', i);
};

</script>

<style scoped lang="less">
.hover-style {
    transform: scale(1);
    &:hover {
        transform: scale(1.33);
    }
}

.active-slide {
    @apply opacity-100;
}

</style>
