<template>
    <PortalOverlay portal="shipping-information-overlay"
                   wrapper-class="w-full md:max-w-390 ml-auto"
                   :show.sync="modalOpen"
                   :close-outside="false"
                   :close-on-route-change="true"
                   :show-close-button="false"
                   :disable-body-scroll="false"
                   :show-blind="true"
                   :side="side">
        <div class="bg-white-100 relative z-3 min-h-screen my-auto px-20 w-full">
            <div class="h-56 border-b border-sand-40 flex p-20 px-0 md:flex">
                <div class="font-medium text-center uppercase font-brandon text-14 md:text-left">
                    {{ shippingInfo?.title }}
                </div>
                <button class="my-auto ml-auto md:block"
                        :aria-label="$translate('generic.Close')"
                        @click="close">
                    <cIcon class="self-center bg-transparent relative"
                           name="close"
                           width="16"
                           height="16"/>
                </button>
            </div>
            <div class="pt-40 md:pt-80">
                <div v-delegate-html-links
                     class="wysiwyg"
                     v-html="shippingInfo?.text"/>
            </div>
        </div>
    </PortalOverlay>
</template>

<script setup lang="ts">
import { ref, watch, computed, onUnmounted } from 'vue';
import bus from '@/core/bus';
import { OpenShippingInformationKey } from '@/project/config/constants';
import BreakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';
import { v4 } from '@/types/serverContract';

const modalOpen = ref(false);
const side = ref('bottom');
const shippingInfo = ref<v4.Products.AdditionalInfo | null>(null);

const setShippingInfoAndOpenOverlay = (shipping: v4.Products.AdditionalInfo) => {
    shippingInfo.value = shipping;
    modalOpen.value = true;
};

const activeBreakpoint = computed(() => {
    return BreakpointsState.activeBreakpoint;
});

watch(activeBreakpoint, () => {
    const isMinMd = BreakpointsState.isBreakpointActive('min-md');
    side.value = isMinMd ? 'right' : 'bottom';
}, { immediate: true });

const close = () => {
    modalOpen.value = false;
};

bus.on(OpenShippingInformationKey, setShippingInfoAndOpenOverlay);

onUnmounted(() => {
    bus.off(OpenShippingInformationKey, setShippingInfoAndOpenOverlay);
});

</script>
