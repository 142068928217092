/*
|-----------------------------------------------------------------------------
| Screens                      https://tailwindcss.com/docs/responsive-design
|-----------------------------------------------------------------------------
|
| Screens in Tailwind are translated to CSS media queries. They define the
| responsive breakpoints for your project. By default Tailwind takes a
| "mobile first" approach, where each screen size represents a minimum
| viewport width. Feel free to have as few or as many screens as you
| want, naming them in whatever way you'd prefer for your project.
|
| Tailwind also allows for more complex screen definitions, which can be
| useful in certain situations. Be sure to see the full responsive
| documentation for a complete list of options.
|
| Class name: .{screen}:{utility}
|
*/

export default {
    xs: '0px', // Pure mobile
    sm: '375px', // Mobile and tablet vertical
    ls: '481px', // Small tablets and mobile (landscape)
    md: '769px', // Up to and including old tablet horizontal
    lg: '1025px', // Desktop
    xl: '1200px', // Desktop large
    print: { raw: 'print' }
};