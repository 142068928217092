<template>
    <footer class="border-t sticky inset-x-0 bottom-0 z-5 border-white-500 bg-sand-10">
        <div class="flex w-full flex-wrap py-8 md:mx-auto md:pb-24 md:pt-16">
            <div class="mx-16 w-full md:mx-40">
                <BasketTotals/>
                <div class="items-center pt-8 xs:-mx-8 xs:flex md:-mx-12 md:pt-16">
                    <div class="w-full xs:px-8 md:px-12">
                        <RouterLink
                            :to="checkoutUrl"
                            class="c-btn c-btn--primary justify-center"
                            @click.native="closeBasket">
                            <span
                                class="c-btn__text whitespace-nowrap text-14 font-brandon-medium leading-16 md:text-16">
                                {{ submitMessage }}
                            </span>
                        </RouterLink>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { router } from '@/core/spa/router';
import translateFilter from '@/core/translation/translate.filter';
import BasketTotals from '@/project/checkout/BasketTotals.vue';
import serverContext from '@/core/serverContext.service';

const emit = defineEmits(['close-basket']);

const checkoutUrl = computed(() => serverContext.checkoutContext.checkoutUrl);
const currentRoute = computed(() => router.currentRoute.path);
const isCheckout = computed(() => checkoutUrl.value === currentRoute.value);

const closeBasket = () => {
    emit('close-basket');
};

const submitMessage = computed(() => {
    if (isCheckout.value) {
        return translateFilter('checkout.Basket.ReturnToCheckout');
    }
    return translateFilter('checkout.Basket.ContinueToCheckout');
});
</script>
