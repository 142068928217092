<template>
    <section>
        <article v-for="facetGroup in facetGroups"
                 :key="facetGroup.key"
                 class="mt-15">
            <h3 class="mt-10">
                {{ facetGroup.key }}
            </h3>
            <checkbox-ctrl v-for="facet in facetGroup.values"
                           :key="facet.key"
                           v-model="facet.selected"
                           :name="facetGroup.key + '_' + facet.key"
                           :label="facet.key + ' (' + facet.count + ')'"
                           @change="facetChange(facetGroup, facet)"/>
        </article>
    </section>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import urlHelper from './../facets/urlHelper.service';
import { FacetGroup, FacetValue } from '@/types/serverContract';

@Component
export default class SearchFacets extends Vue {
    @Prop({
        required: true
    }) facetGroups!: FacetGroup[];

    facetChange(facetGroup: FacetGroup, facet: FacetValue) {
        urlHelper.updateFacetValue(facetGroup.key, facet.key, facet.selected);
    }
}
</script>
