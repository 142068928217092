<template>
    <component :is="tag">
        <slot :viewed="viewed"/>
    </component>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class Interceptor extends Vue {
    @Prop({ type: Number, required: false, default: 0 })
        offset!: number;

    @Prop({ type: Number, required: false, default: 1.0 })
        threshold!: number;

    @Prop({ type: Boolean, required: false })
        keepObserving!: number;

    @Prop({ type: String, default: 'div' }) tag!: string;

    viewed: boolean = false;
    observer: IntersectionObserver | null = null;
    observe(): void {
        const options: IntersectionObserverInit = { rootMargin: `${this.offset}px`, threshold: this.threshold };
        this.observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.viewed = true;
                    if (!this.keepObserving) {
                        this.unObserve();
                    }
                }
            });
        }, options);
        this.observer.observe(this.$el);
    }

    unObserve(): void {
        if (this.observer) {
            this.observer.unobserve(this.$el);
            this.observer.disconnect();
            this.observer = null;
        }
    }

    mounted() {
        this.observe();
    }

    public destroyed() {
        this.unObserve();
    }
}
</script>
