<template>
    <div v-if="modifiedByServer"
         class="my-20 p-20 bg-white-600">
        <div class="flex">
            <p class="pr-20">
                <span class="align-text-top">{{ $translate('checkout.ModifiedByServer.Label') }}</span>
            </p>
            <button class="ml-auto mb-auto"
                    @click="setModifiedByServer(false)">
                <c-icon name="close"
                        width="12"/>
            </button>
        </div>
        <ul class="mt-5">
            <li v-for="removed in loadedProducts"
                :key="removed.sku"
                class="mb-2 font-light"
                v-html="labelForRemovedLine(removed.product, removed.removed, removed.sku)"/>
        </ul>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import basketStore from '@/store/basket.store';
import { v4 } from '@/types/serverContract';
import Api from '@/project/http/api';
import translateFilter from '@/core/translation/translate.filter';
import { getSelectedVariantName, getVariantBySku, productHasVariants } from '@/project/product/productHelper.utils';

interface iModifiedByServerProductInformation {
    sku: string;
    product: null | v4.Products.ProductSimple;
    removed: boolean;
}
@Component
export default class BasketModifiedByServer extends Vue {
    loadedProducts: iModifiedByServerProductInformation[] | null = null;
    pending: boolean = false;

    loadProducts() {
        const askedForSkus = this.modifiedByServerRemovedSkus.concat(this.modifiedByServerModifiedSkus);
        if (askedForSkus.length && this.pending === false) {
            this.pending = true;
            Api.catalog.productListByVariant(askedForSkus, true).then((products) => {
                askedForSkus.map((sku) => {
                    const product = products.find((p) => {
                        return p?.variants.find((v) => {
                            return v.sku === sku;
                        });
                    });
                    this.loadedProducts = [];
                    const wasProductRemoved = this.modifiedByServerRemovedSkus.includes(sku);
                    this.loadedProducts.push({
                        sku,
                        product: product || null,
                        removed: !!wasProductRemoved
                    });
                });
            }).finally(() => {
                this.pending = false;
            });
        }
    }

    @Watch('modifiedByServerModifiedSkus', { immediate: true })
    modifiedByServerModifiedSkusChanged() {
        this.loadProducts();
    }

    @Watch('modifiedByServerRemovedSkus', { immediate: true })
    modifiedByServerRemovedSkusChanged() {
        this.loadProducts();
    }

    get modifiedByServer() : boolean {
        return basketStore.modifiedByServer;
    }

    get modifiedByServerModifiedSkus() {
        return basketStore.modifiedByServerModifiedSkus;
    }

    get modifiedByServerRemovedSkus() {
        return basketStore.modifiedByServerRemovedSkus;
    }

    setModifiedByServer(state:boolean) {
        basketStore.setModifiedByServer(state);
    }

    labelForRemovedLine(product: null | v4.Products.ProductSimple, lineRemoved: boolean, sku: string): string {
        let productName = '';
        if (product) {
            const variant = getVariantBySku(product.variants, sku);
            productName = product.name;
            if (productHasVariants(product)) {
                productName += ' ' + getSelectedVariantName(variant);
            }
        } else {
            productName = sku;
        }
        return translateFilter(lineRemoved ? 'checkout.ModifiedByServer.LineRemoved' : 'checkout.ModifiedByServer.LineQuantityModified', `<span class="font-normal">${productName}</span>`);
    }
}
</script>
