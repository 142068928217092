<template>
    <PortalOverlay portal="energy-label-overlay"
                   wrapper-class="w-full md:max-w-390 ml-auto"
                   :show.sync="open"
                   :disable-body-scroll="true"
                   :show-close-button="false"
                   :close-outside="false"
                   :show-blind="true"
                   :close-on-route-change="true"
                   side="right">
        <div class="bg-white-100 relative z-3 min-h-screen my-auto px-20 w-full">
            <div class="h-56 border-b border-sand-40 flex">
                <div class="flex-grow leading-13 text-center md:text-left my-auto -mr-20 font-brandon text-13 font-medium uppercase">
                    {{ $translate('product.EnergyClass.OverlayHeading') }}
                </div>
                <button class="my-auto ml-auto md:block"
                        :aria-label="$translate('generic.Close')"
                        @click="close">
                    <cIcon class="self-center bg-transparent relative"
                           name="close"
                           width="16"
                           height="16"/>
                </button>
            </div>
            <section v-if="variant">
                <div class="flex flex-col items-center">
                    <img class="max-w-200 mt-40 md:mt-80 mb-16 md:mb-80"
                         :src="energyLabel?.energyLabel?.url"
                         :alt="altText">

                    <div class="w-full border-t border-b border-sand-40 py-24">
                        <div class="flex justify-between">
                            <span>{{ energyLabel?.energyClass?.key }}</span>
                            <span>{{ energyLabel?.energyClass?.value }}</span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </PortalOverlay>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import bus from '@/core/bus';
import { OpenEnergyLabelKey } from '@/project/config/constants';
import type { v4 } from '@/types/serverContract';

@Component
export default class EnergyLabelOverlay extends Vue {
    open: boolean = false;
    variant: v4.Products.VariantSimple | null = null;

    created() {
        bus.on(OpenEnergyLabelKey, this.setVariantAndShowOverlay);
    }

    destroyed() {
        bus.off(OpenEnergyLabelKey, this.setVariantAndShowOverlay);
    }

    get energyLabel(): v4.Products.EnergyClassLabel | undefined {
        const firstLabel = this.variant?.labellingsFlattened?.[0];
        if (!firstLabel) {
            return undefined;
        }
        return (firstLabel as v4.Products.EnergyClassLabel).energyClass ? (firstLabel as v4.Products.EnergyClassLabel) : undefined;
    }

    get altText() {
        // @ts-ignore
        return `${this.variant?.labellingsFlattened?.[0].energyClass?.key} ${this.variant?.labellingsFlattened?.[0].energyClass?.value}` || '';
    }

    setVariantAndShowOverlay(variant: v4.Products.VariantSimple) {
        this.variant = variant;
        this.open = true;
    }

    close() {
        this.open = false;
    }
}
</script>
