<template>
    <ResponsiveIcon v-if="isImage"
                    :image-url="image.url"
                    :width="image.width"
                    :height="image.height"
                    :alt-text="altText"
                    :bg-color="bgColor"/>
    <LottieWrap v-else-if="isLottie"
                :image="image"
                :alt-text="altText"/>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Media } from '@/types/serverContract';
import ResponsiveIcon from '@/core/responsive/image/ResponsiveIcon.vue';
const LottieWrap = () => import('@/project/shared/LottieWrap.vue');
@Component({
    components: {
        LottieWrap,
        ResponsiveIcon
    }
})
export default class ResponsiveIconWrap extends Vue {
    @Prop()
        image!:Media;

    @Prop()
        altText!:string;

    @Prop()
        bgColor!:string;

    get isImage(): boolean {
        return this.image && this.image.extension.match('png|gif|jpg|jpeg|svg') !== null;
    }

    get isLottie(): boolean {
        return this.image && this.image.extension.match('json') !== null;
    }
}
</script>
