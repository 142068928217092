import Cookies from 'js-cookie';
import serverContext from '@/core/serverContext.service';
import { ServerCultureModel } from '@/types/serverContract';

const cookieName = 'culture';

function setCultureCookie(isoCode: string): void {
    Cookies.set(cookieName, isoCode, { expires: 30 });
}

function getCookieCulture(): ServerCultureModel | undefined {
    return cultureForIsoCode(Cookies.get(cookieName));
}

function serverCulture(): ServerCultureModel | undefined {
    return serverContext.cultures.find(culture => serverContext.culture === culture.isoCode);
}

function cultureForIsoCode(isoCode: string | undefined): ServerCultureModel | undefined {
    return serverContext.cultures.find(culture => isoCode === culture.isoCode);
}

function serverCultures(): ServerCultureModel[] {
    return serverContext.cultures;
}

// Overwrite with servers opinion on isoCode
if (serverContext.culture) {
    setCultureCookie(serverContext.culture);
}

export default {
    getCookieCulture,
    serverCulture,
    cultureForIsoCode,
    serverCultures
};
