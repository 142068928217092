import { ref } from 'vue';
import { HeaderNavigationModel } from '@/types/serverContract';
import Api from '@/project/http/api';
import loggingService from '@/core/logging.service';

const headerNavigation = ref<HeaderNavigationModel | null>(null);

export const useHeaderNavigation = () => {
    async function setNavigation() {
        try {
            headerNavigation.value = await Api.navigation.get();
            window.vertica.headerNavigation = headerNavigation.value;
        } 
        catch(e) {
            loggingService.exception('Failed to set search results', e);
        }
    }

    return {
        headerNavigation,
        setNavigation
    };
};

export default useHeaderNavigation;
