<template>
    <component :is="tag"
               v-if="text !== undefined"
               :class="{'wysiwyg': wysiwyg}"
               v-html="sanitizedText"/>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import sanitizeUmbracoText from '@/core/sanitize.service';

@Component
export default class UmbracoText extends Vue {
    @Prop({ type: String, default: '' }) text!: string;
    @Prop({ type: String, default: 'span' }) tag!: string;
    @Prop({ type: Boolean, default: false }) wysiwyg!: boolean;

    get sanitizedText() {
        if (this.wysiwyg) {
            // TODO: sanitize HTML
            return this.text;
        } else {
            return sanitizeUmbracoText(this.text);
        }
    }
}
</script>
