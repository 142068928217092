<template>
    <div class="flex flex-col">
        <form
            ref="form"
            novalidate
            role="form"
            :aria-label="$translate('login.ResetPassword.RequestReset.Heading')"
            @submit.prevent="submitForm">
            <transition
                mode="out-in"
                appear
                leave-active-class="animated fadeOut u-anim-dur-200"
                enter-active-class="animated fadeIn u-anim-dur-300">
                <div
                    v-if="mode === ForgotPasswordRequestFormModes.success"
                    :key="'success'"
                    class="mb-65 flex flex-col md:mb-56">
                    <div class="mx-auto h-150 w-150">
                        <LottieWrap
                            :image="paperPlane"
                            :alt-text="$translate('login.ResetPassword.RequestReset.HeadingSuccess')"/>
                    </div>
                    <h3 class="my-32 text-center text-25 font-brandon-regular uppercase leading-none">
                        {{ $translate('login.ResetPassword.RequestReset.HeadingSuccess') }}
                    </h3>
                    <p class="mx-auto mb-40 max-w-390 text-center text-13 font-gibson-light leading-16 md:mb-17">
                        {{ $translate('login.ResetPassword.RequestReset.Success') }}
                    </p>
                    <div class="mx-auto max-w-290">
                        <button
                            v-if="successSubmitClickHandler"
                            class="c-btn c-btn--primary h-40 w-full justify-center md:h-56"
                            @click="successSubmitClickHandler">
                            <span class="c-btn__text tracking-medium md:tracking-medium-heading">{{ $translate(successSubmit) }}</span>
                        </button>
                        <router-link
                            v-else
                            :to="frontpageUrl"
                            class="c-btn c-btn--primary h-40 w-full justify-center md:h-56">
                            <span class="c-btn__text tracking-medium md:tracking-medium-heading">{{ $translate(successSubmit) }}</span>
                        </router-link>
                    </div>
                </div>
                <div v-else-if="mode === ForgotPasswordRequestFormModes.error"
                     :key="'error'"
                     class="mb-65 md:mb-56">
                    <div
                        class="mx-auto mb-40 mt-40 max-w-390 text-center text-13 font-gibson-light leading-16 text-red-500">
                        {{ $translate('login.ResetPassword.RequestReset.Error') }}
                    </div>
                    <div class="mx-auto max-w-290">
                        <button
                            class="c-btn c-btn--primary h-40 w-full justify-center md:h-56"
                            type="button"
                            @click="errorRetry">
                            <span class="c-btn__text tracking-medium md:tracking-medium-heading">{{ $translate('login.ResetPassword.RequestReset.ErrorRetry') }}</span>
                        </button>
                    </div>
                </div>
                <div v-else
                     :key="'init'"
                     class="mb-65 md:mb-56">
                    <div class="flex justify-center">
                        <cIcon name="rating"
                               width="58"
                               height="87"/>
                    </div>
                    <h3 class="my-32 text-center text-25 font-brandon-regular uppercase leading-none">
                        {{ $translate('login.ResetPassword.RequestReset.Heading') }}
                    </h3>
                    <p class="mx-auto mb-40 max-w-390 text-center text-13 font-gibson-light leading-16 md:mb-17">
                        {{ $translate('login.ResetPassword.RequestReset.Text') }}
                    </p>
                    <div class="mx-auto max-w-290">
                        <text-input
                            v-model="email"
                            class="mb-15"
                            name="forgotemail"
                            type="email"
                            autocomplete="email"
                            :autofocus="firstFieldAutofocus"
                            :label="$translate('login.CreateUser.Fields.Email')"
                            :data-vv-as="$translate('login.CreateUser.Fields.Email')"
                            :data-vv-validate-on="formFieldsValidateOn"
                            :show-error="showFormErrors"
                            :constraints="validationRuleEmailField"
                            :placeholder="$translate('login.CreateUser.Fields.Email')"
                            :maxlength="130"/>
                        <button
                            type="submit"
                            :disabled="submitButtonDisabled"
                            class="c-btn c-btn--primary h-40 w-full justify-center md:h-56">
                            <span class="c-btn__text tracking-medium md:tracking-medium-heading">{{ $translate('login.ResetPassword.RequestReset.Submit') }}</span>
                        </button>
                    </div>
                </div>
            </transition>
        </form>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Api from '@/project/http/api';
import { ForgotPasswordRequestFormModes } from '@/project/login/LoginModes';
import { doDataLayerPush } from '@/project/tracking/googleTagManager.utils';
import trackingUtils from '@/project/tracking/tracking.utils';
import serverContext from '@/core/serverContext.service';
import { validationRuleEmailField, isTouchDevice } from '@/project/shared/string.util';

import { Media, MediaType, ImageType } from '@/types/serverContract';
import ResponsiveIcon from '@/core/responsive/image/ResponsiveIcon.vue';
const LottieWrap = () => import('@/project/shared/LottieWrap.vue');
@Component({
    components: { LottieWrap, ResponsiveIcon }
})
export default class ForgotPasswordForm extends Vue {
    mode: ForgotPasswordRequestFormModes = ForgotPasswordRequestFormModes.init;
    email: string = '';
    password: string = '';
    persistent: boolean = false;
    formFieldsValidateOnDefault: string = 'blur|change';
    showFormErrors: boolean = true;
    formPending: boolean = false;

    @Prop()
        isInFocus!: boolean;

    @Prop({ type: String, default: 'generic.Close' })
        successSubmit!: string;

    @Prop()
        successSubmitClickHandler!: (event) => void;

    // From Vue-validate
    vvErrors!: any;

    // From Vue-validate
    vvFields!: any;

    @Watch('isInFocus')
    isInFocusChanged(isInFocus: boolean) {
        if (isInFocus) {
            doDataLayerPush({
                event: 'overlay',
                overlayName: 'forgotPasswordForm'
            });
        }
    }

    get paperPlane(): Media {
        return {
            type: MediaType.Image,
            imageType: ImageType.Unspecified,
            url: '/images/newsletter-signup.json',
            name: 'Paper plane',
            width: 150,
            height: 150,
            extension: 'json',
            focalPoint: { left: 0, top: 0 },
            config: {
                playbackMode: 'infinite'
            }
        };
    }

    get formFieldsValidateOn() {
        return this.isInFocus ? this.formFieldsValidateOnDefault : null;
    }

    get submitButtonDisabled(): boolean {
        return this.formPending;
    }

    get firstFieldAutofocus(): boolean {
        return this.isInFocus && !isTouchDevice();
    }

    // Helper function to get enum type.
    get ForgotPasswordRequestFormModes() {
        return ForgotPasswordRequestFormModes;
    }

    async validateAll() {
        return this.$validator.validateAll();
    }

    clearValidation() {
        this.$validator.reset();
    }

    errorRetry() {
        this.mode = ForgotPasswordRequestFormModes.init;
    }

    async submitForm() {
        const formName: string = 'ForgotPassword';
        trackingUtils.forms.trackFormSubmitClick(formName);

        // this.showFormErrors = true;
        const valid = this.validateAll();
        await valid;
        const isValid = !(this.vvErrors && this.vvErrors.items && this.vvErrors.items.length);
        if (!isValid || this.formPending) {
            trackingUtils.forms.trackFormValidationFail(formName);
            return;
        }
        this.formPending = true;
        await Api.user
            .resetPassword(this.email)
            .then(() => {
                trackingUtils.forms.trackFormValidationSuccess(formName);
                this.mode = ForgotPasswordRequestFormModes.success;
                this.$emit('reset-password');
            })
            .catch(() => {
                trackingUtils.forms.trackFormValidationFail(formName);
                this.mode = ForgotPasswordRequestFormModes.error;
            })
            .finally(() => (this.formPending = false));
    }

    success() {
        this.$emit('success');
    }

    abort() {
        this.$emit('abort');
    }

    login() {
        this.$emit('change-to-login-form');
    }

    get frontpageUrl(): string {
        return serverContext.marketUrl;
    }

    get validationRuleEmailField() {
        return validationRuleEmailField();
    }
}
</script>
