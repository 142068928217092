<template>
    <div>
        <PortalOverlay portal="variant-picker-overlay"
                       wrapper-class="variant-picker-overlay-wrap w-full md:max-w-480 ml-auto top-auto"
                       :show.sync="modalOpen"
                       :close-outside="false"
                       :show-close-button="false"
                       :disable-body-scroll="false"
                       :show-blind="true"
                       :close-on-route-change="true"
                       :side="side">
            <ProductDetailsToasts positioning="sticky top-0 pt-70"/>
            <div class="flex flex-col h-full">
                <div class="mb-32 w-full sticky top-0 z-1">
                    <div class="border-b bg-sand-10 border-sand-40 p-20 h-56 md:flex">
                        <MiniBasket v-if="isMinMd"
                                    :animate="true"
                                    class="mr-24"/>
                        <h3 class="font-medium text-center uppercase font-brandon text-14 mt-2 md:text-left">
                            {{ title }}
                        </h3>
                        <button class="absolute my-auto right-0 top-1/2 mr-20 -mt-10"
                                :aria-label="$translate('generic.Close')"
                                @click="close">
                            <cIcon class="self-center bg-transparent relative"
                                   name="close"
                                   width="16"
                                   height="16"/>
                        </button>
                    </div>
                </div>
                <Transition mode="out-in"
                            leave-active-class="animated fadeOut u-anim-dur-200"
                            enter-active-class="animated fadeIn u-anim-dur-200">
                    <ImageSlider v-if="selectedVariant && product"
                                 :key="selectedVariant.sku"
                                 :selected-variant="selectedVariant"
                                 :product="product"/>
                </Transition>
                <div v-if="product"
                     class="mt-32 px-20 w-full mx-auto">
                    <ProductTileColorVariant v-if="isColorVariantType"
                                             :key="product?.id"
                                             :product="product"
                                             :selected-variant="selectedVariant"
                                             class="mb-8"
                                             @on-variant-set="setSelected"/>
                    <ProductTileTextVariant v-else-if="isTextVariantType"
                                            :key="product?.id"
                                            :product="product"
                                            :selected-variant="selectedVariant"
                                            class="mb-8"
                                            @on-variant-set="setSelected"/>
                </div>
                <div v-if="product && selectedVariant"
                     class="sticky bottom-0 flex items-center mt-auto z-1 border-t w-full min-h-88 border-0 border-sand-40 bg-sand-10 px-20 lg:pb-8 shrink-0">
                    <div class="flex items-center gap-16 w-full">
                        <ProductTileVariantPickerPrice :product="product"
                                                       :selected-variant="selectedVariant"
                                                       class="flex-1"/>
                        <div class="flex-1 my-12">
                            <VariantRemindMeButton v-if="selectedVariant.allowRemindMe" 
                                                   class="mb-8"
                                                   :product="product"
                                                   :variant="selectedVariant"/>
                            <template v-if="selectedVariant?.packageQuantity && selectedVariant?.packageQuantity > 1">
                                <SplitTest feature-key="Fsst-package_quantity"
                                           default-variation="disabled"
                                           is-fallback>
                                    <ProductTileAddToCartButton v-if="inventory"
                                                                :key="selectedVariant?.sku"
                                                                :product="product"
                                                                :inventory="inventory"
                                                                :selected-variant="selectedVariant"/>
                                </SplitTest>
                                <SplitTest feature-key="Fsst-package_quantity"
                                           variation="enabled"
                                           default-variation="disabled">
                                    <ProductTileAddToCartButton v-if="inventory"
                                                                :key="selectedVariant?.sku"
                                                                should-use-package-quantity
                                                                :product="product"
                                                                :inventory="inventory"
                                                                :selected-variant="selectedVariant"/>
                                </SplitTest>
                            </template>
                            <template v-else>
                                <ProductTileAddToCartButton v-if="inventory"
                                                            :key="selectedVariant?.sku"
                                                            :product="product"
                                                            :inventory="inventory"
                                                            :selected-variant="selectedVariant"/>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </PortalOverlay>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, onUnmounted, watch, defineAsyncComponent } from 'vue';
import { v4 } from '@/types/serverContract';
import bus from '@/core/bus';
import { CloseVariantPickerKey, OpenVariantPickerKey } from '@/project/config/constants';
import BreakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';
import ProductTileAddToCartButton from '@/project/product/ProductTileAddToCartButton.vue';
import ProductDetailsToasts from '@/project/product/details/ProductDetailsToasts.vue';
import ProductTileVariantPickerPrice from '@/project/product/ProductTileVariantPickerPrice.vue';
import ImageSlider from '@/project/product/variant/ImageSlider.vue';
import MiniBasket from '@/project/checkout/MiniBasket.vue';
import translateFilter from '@/core/translation/translate.filter';
import useProductTracking from '@/project/product/useProductTracking';
import VariantRemindMeButton from '@/project/product/variant/VariantRemindMeButton.vue';
import SplitTest from '@/project/growthbook/SplitTest.vue';
const ProductTileColorVariant = defineAsyncComponent(() => import('@/project/product/ProductTileColorVariant.vue'));
const ProductTileTextVariant = defineAsyncComponent(() => import('@/project/product/ProductTileTextVariant.vue'));

interface VariantSelectorInfo {
    selectedVariant: v4.Products.VariantSimple;
    product: v4.Products.ProductSimple;
    inventory: v4.Products.Inventory;
}

const product = ref<v4.Products.ProductSimple | null>(null);
const selectedVariant = ref<v4.Products.VariantSimple | null>(null);
const inventory = ref<v4.Products.Inventory | null>(null);
const side = ref('bottom');
const modalOpen = ref(false);
const { unsetGlobalTempContext } = useProductTracking();

bus.on(OpenVariantPickerKey, setData);
bus.on(CloseVariantPickerKey, close);

const setSelected = (variant: v4.Products.VariantSimple) => {
    selectedVariant.value = variant;
};

const activeBreakpoint = computed(() => {
    return BreakpointsState.activeBreakpoint;
});
const title = computed(() => {
    return isColorVariantType.value ? translateFilter('product.Variant.PickColor') : isTextVariantType.value ? translateFilter('product.Variant.PickVariant') : '';
});

const isColorVariantType = computed(() => {
    return product.value?.variantInfo?.type === v4.Products.GenericVariantType.Color;
});

const isTextVariantType = computed(() => {
    return product.value?.variantInfo?.type === v4.Products.GenericVariantType.Text;
});

const isMinMd = computed(() => {
    return BreakpointsState.isBreakpointActive('min-md');
});

watch(activeBreakpoint, () => {
    side.value = isMinMd.value ? 'right' : 'bottom';
}, { immediate: true });

watch(modalOpen, () => {
    if (!modalOpen.value) {
        unsetGlobalTempContext();
    }
});

function setData(variantSelectorInfo: VariantSelectorInfo) {
    product.value = variantSelectorInfo.product;
    selectedVariant.value = variantSelectorInfo.selectedVariant;
    inventory.value = variantSelectorInfo.inventory;
    modalOpen.value = true;
}

function close() {
    modalOpen.value = false;
}

onUnmounted(() => {
    bus.off(OpenVariantPickerKey, setData);
    bus.off(CloseVariantPickerKey, close);
});

</script>

<style lang="less">
.variant-picker-overlay-wrap {
    top: auto;
    bottom: 0;
    height: 60.5rem;
    @apply bg-white-100;
}

.variant-wrap {
    @apply relative z-3 min-h-full h-full my-auto px-20 w-full flex flex-col;
}

@screen md {
    .variant-wrap {
        margin-top: auto !important;
    }
}

@screen md {
    .variant-picker-overlay-wrap {
        top: 0;
        height: 100vh;
    }
}
</style>
