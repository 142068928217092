<template>
    <form :aria-label="placeHolderText"
          class="flex"
          novalidate
          action=""
          @submit.prevent="navigateOnSubmit(searchTerm)"
          @keyup.esc="onEscPressed">
        <div class="flex flex-shrink-0 pl-8 rounded-tl-3 rounded-bl-3 bg-sand-10 md:bg-sand-20"
             tabindex="-1">
            <cIcon class="mr-12 flex-shrink-0 self-center bg-transparent"
                   name="search"
                   width="16"
                   height="16"/>
        </div>
        <div class="relative w-full">
            <input
                ref="searchInputRef"
                v-model.trim="searchTerm"
                v-prohibit-zoom
                autocomplete="off" 
                autocorrect="off"
                spellcheck="false"
                type="search"
                enterkeyhint="search"
                :placeholder="placeHolderText"
                class="search-input bg-sand-10 h-40 md:h-32 md:bg-sand-20"
                :class="{'search-active': searchActive}"
                @focus="searchActive = true">
            <button v-show="searchTerm.length"
                    type="button" 
                    class="absolute top-0 right-0 bottom-0 my-auto mr-8" 
                    :aria-label="$translate('search.Overlay.ClearTermField')"
                    @click.prevent="clearTerm">
                <c-icon name="clear"
                        class="w-12 h-12"
                        width="12"
                        height="12"/>
            </button>
        </div>
    </form>
</template>

<script lang="ts" setup>
import useSearch from '@/project/search/useSearch';
import debounce from 'lodash-es/debounce';
import { onMounted, ref, watch, computed, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router/composables';
import translateFilter from '@/core/translation/translate.filter';
import serverContextService from '@/core/serverContext.service';
import scrollService from '@/core/scroll/scroll.service';

const searchInputRef = ref<HTMLElement | null>(null);
const debounceValue = 500;
const route = useRoute();

const debounceSearch = debounce((term) => search(term), debounceValue);
const { setSearchSuggestions, searchTerm, setQueryParam, searchActive, closeOverlay, getQueryParam, clearResults, resetSuggestionsState } = useSearch();

const search = async(term) => {
    if (!term) return;

    await setSearchSuggestions(term);
};

searchTerm.value = getQueryParam('term') as string;

const placeHolderText = computed(()=> {
    return searchActive.value ? translateFilter('search.PlaceholderActive') : translateFilter('search.Placeholder');
});

const navigateOnSubmit = async(term)=> {
    closeSearchOverlay();
    if (term && term.length > 1) {
        setQueryParam(term);
    }
};

onMounted(() => {
    search(searchTerm.value);

    if (searchActive.value) {
        searchInputRef.value?.focus();
    }
});

const closeSearchOverlay = ()=> {
    searchInputRef.value?.blur();
    closeOverlay();
};

const onEscPressed = ()=> {
    closeSearchOverlay();
    searchTerm.value = '';
};

const clearTerm = ()=> {
    searchTerm.value = '';
    searchInputRef.value?.focus();
};

watch(searchTerm, (val, oldVal) => {
    if (val && val.length >= 2 && val !== oldVal) {
        resetSuggestionsState();
        debounceSearch(val);
    }
});

watch([() => route.path, () => route.query], ([newPath, newQuery], [oldPath, oldQuery])=> {
    if ((newPath !== oldPath || newQuery !== oldQuery) && newPath !== serverContextService.searchContext.batchSearchResultUrl) {
        closeSearchOverlay();
        // Defer clearResult to run after DOM update
        setTimeout(()=> {
            clearResults();
        });
        searchTerm.value = '';
    }
    else {
        closeSearchOverlay();
        const newTerm = newQuery && typeof newQuery.term === 'string' ? newQuery.term : '';
        searchTerm.value = newTerm;
    }
});

const bodyElm = document.querySelector('body');

watch(searchActive, (newVal)=> {
    if (newVal) {
        scrollService.disableBodyScroll(bodyElm!);
    }
    else {
        scrollService.enableBodyScroll(bodyElm!);
    }
}, { immediate: true });

onBeforeUnmount(() => {
    scrollService.enableBodyScroll(bodyElm!);
});

</script>

<style lang="less" scoped>
.search-input {
    @apply flex-shrink flex-grow block w-full pr-8 font-gibson font-normal text-14 rounded-tr-3 rounded-br-3 leading-16 opacity-100;

    &::placeholder {
        @apply font-brandon font-normal leading-18 text-brown-50 opacity-100 pl-3;
    }

    &.search-active {
        &::placeholder {
            @apply font-gibson font-normal;
        }
    }


}
</style>