<template>
    <div>
        <breadcrumb/>
        <blocks-container :blocks="content && content.blocks"/>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { HomeContentModel } from '@/types/serverContract';
import Breadcrumb from '@/project/shared/navigation/Breadcrumb.vue';

@Component({
    components: {
        Breadcrumb
    }
})
export default class Home extends Vue {
    @Prop() content!: HomeContentModel;
}
</script>

<style scoped>

</style>
