<template>
    <div v-click-outside="clear">
        <div v-show="labelling.labels.length > 0"
             class="font-brandon-medium leading-16 mb-16 uppercase">
            {{ labelling.name }}
        </div>
        <div class="flex flex-col">
            <div v-for="(section, indexSection) in chunkedLabels"
                 :key="`section${indexSection}`"
                 class="relative mb-12 flex flex-wrap last:mb-0">
                <button v-for="(label, indexLabel) in section"
                        :key="`${indexLabel}+${label.name}`"
                        :title="label.name"
                        class="mr-8 last:mr-0 flex-none"
                        @click="labelClicked(label, indexSection, indexLabel)">
                    <LabellingIcon
                        :key="label.name"
                        :label="label"
                        class="pb-8 border-b-2 border-transparent h-full"
                        :class="{'border-brown-80': `${indexSection}+${indexLabel}` === clickedIndex && showInformation}"/>
                </button>

                <div class="break"></div>
                <transition mode="out-in"
                            leave-active-class="animated fadeOut u-anim-dur-200"
                            enter-active-class="animated fadeIn u-anim-dur-200">
                    <div v-if="indexSection === clickedSection && showInformation && labelInformation"
                         :key="labelInformation.name"
                         class="bg-sand-20 p-24 w-full">
                        <component :is="getLabelTypeComponent"
                                   :data="labelInformation"/>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { v4 } from '@/types/serverContract';
import { computed, defineAsyncComponent, ref } from 'vue';
import LabellingIcon from './LabellingIcon.vue';
import chunk from 'lodash-es/chunk';
import trackingUtils from '@/project/tracking/tracking.utils';
const EnergyLabelInformation = defineAsyncComponent(() => import('@/project/product/details/EnergyLabelInformation.vue'));
const DefaultLabelInformation = defineAsyncComponent(() => import('@/project/product/details/DefaultLabelInformation.vue'));

const props = defineProps<{
    labelling: v4.Products.LabelGrouping,
    index: number
}>();

const clickedIndex = ref<string | null>(null);
const clickedSection = ref<number | null>(null);
const labelInformation = ref<v4.Products.Label | v4.Products.EnergyClassLabel | null>(null);
const showInformation = ref(false);
const chunkedLabels = ref(chunk(props.labelling.labels, 4));

const setShowInformation = (val) => {
    showInformation.value = val;
};

const clear = () => {
    showInformation.value = false;
    clickedIndex.value = null;
    clickedSection.value = null;
    labelInformation.value = null;
};

const getLabelTypeComponent = computed(() => {
    const isLabelTypeEnergy = labelInformation.value?.type === v4.Products.LabelType.EnergyClass;
    return isLabelTypeEnergy ? EnergyLabelInformation : DefaultLabelInformation;
});

const labelClicked = (icon: v4.Products.Label | v4.Products.EnergyClassLabel, indexSection: number, indexIcon: number) => {
    setShowInformation((`${indexSection}+${indexIcon}` !== clickedIndex.value) || (showInformation.value = !showInformation.value));
    clickedIndex.value = `${indexSection}+${indexIcon}`;
    clickedSection.value = indexSection;
    labelInformation.value = icon;
    trackingUtils.navigation.trackAccordionOpen(`productDetailsLabel_${icon.id}`);
};

</script>

<style lang="less" scoped>
.break {
    flex-basis: 100%;
    height: 0;
}
</style>
