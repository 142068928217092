import { StorageService } from '@/core/storage.service';
import userStore from '@/store/user.store';

const NUDGE_KEY = 'preferredStorePickNudgeCount';
const NUDGE_TRIGGERS = [1, 4, 10];

export function shouldNudgeForPreferredStorePick(): { shouldNudge: boolean; nudgeCount: number } {
    if (!userStore.isLoggedIn || userStore.userFavoriteStore) return { shouldNudge: false, nudgeCount: 0 };

    let nudgeCount = getNudgeCount();
    nudgeCount++;
    StorageService.setItem(NUDGE_KEY, nudgeCount);

    const shouldNudge = NUDGE_TRIGGERS.includes(nudgeCount);
    return { shouldNudge, nudgeCount };
}

function getNudgeCount() {
    let nudgeCount = +(StorageService.getItem(NUDGE_KEY) || 0);
    nudgeCount = isNaN(nudgeCount) ? 0 : nudgeCount;
    return nudgeCount;
}
