import Vue from 'vue';

import App from '@/App.vue';
import RouterLinkConditional from '@/core/router-link-conditional.vue';
import Breakpoints from '../responsive/breakpoints/Breakpoints.vue';
import PortalOverlay from '../portal-overlay/PortalOverlay.vue';

Vue.component('App', App);
Vue.component('Breakpoints', Breakpoints);
Vue.component('RouterLinkConditional', RouterLinkConditional);
Vue.component('PortalOverlay', PortalOverlay);
