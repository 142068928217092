<template>
    <article>
        <div v-if="facetGroup">
            <header :class="classHeader">
                <span>
                    {{ title }}
                </span>
            </header>
            <ul class="flex flex-wrap items-center -ml-10">
                <li v-for="facet in facetGroup.values"
                    :key="facet.key"
                    :class="classListItems">
                    <button type="button"
                            class="w-full pt-10 pb-6 relative font-light text-12 leading-sm uppercase"
                            :aria-label="facet.key"
                            :title="facet.label"
                            :class="facetButtonClass(facet)"
                            @click="handleClickFacet(facetGroup, facet)">
                        <span>
                            {{ facet.label }}
                        </span>
                        <c-icon v-if="facet.selected"
                                width="10"
                                name="close"
                                class="absolute right-10 top-1/2 -mt-5"/>
                    </button>
                </li>
            </ul>
        </div>
    </article>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import urlHelper from '@/project/facets/urlHelper.service';
import { Prop } from 'vue-property-decorator';
import { FacetGroup, FacetValue } from '@/types/serverContract';

import trackingUtils from '@/project/tracking/tracking.utils';
import translateFilter from '@/core/translation/translate.filter';

@Component
export default class SearchFilterButtons extends Vue {
    @Prop({ type: String, default: translateFilter('search.Filters.Material') })
        title!: string;

    @Prop()
        facetGroup!: FacetGroup | undefined;

    @Prop()
        classHeader!: string;

    @Prop()
        classListItems!: string;

    @Prop()
        classListButton!: string;

    @Prop()
        buttonBackground!: string;

    @Prop()
        buttonSelectedBackground!: string;

    handleClickFacet(facetGroup: FacetGroup, facet: FacetValue) {
        facet.selected = !facet.selected;
        this.facetChange(facetGroup, facet);
    }

    facetChange(facetGroup: FacetGroup, facet: FacetValue) {
        trackingUtils.facetFilter.trackFacetAddOrRemove(facet.selected, facetGroup.key, facet?.key, urlHelper.getFacets());
        urlHelper.updateFacetValue(facetGroup.key, facet.key, facet.selected);
    }

    facetButtonClass(facet:FacetValue) {
        return [this.classListButton, facet.selected ? this.buttonSelectedBackground : '', !facet.selected ? this.buttonBackground : ''];
    }
}
</script>
