<template>
    <span>
        <button class="c-modal-toggle underline hover:no-underline"
                type="button"
                @click="toggle">
            {{ label }}
        </button>
        <OverlayWrapper :open="show" 
                        portal="checkout-terms-overlay"
                        @cancelled="close">
            <template #header>
                <OverlayHeader 
                    @close="close">
                    {{ title }}
                </OverlayHeader>
            </template>
            <template #body>
                <div 
                    v-delegate-html-links
                    class="c-modal-wysiwyg wysiwyg"
                    v-html="text"></div>
            </template>
        </OverlayWrapper>
    </span>
</template>
<script setup lang="ts">
import OverlayWrapper from '@/project/shared/overlay/OverlayWrapper.vue';
import OverlayHeader from '@/project/shared/overlay/OverlayHeader.vue';
import { useToggle } from '@/project/product/details/useToggle';

const { value: show, close, toggle } = useToggle();

defineProps<{
    label: string;
    title: string;
    text: string;
}>();
</script>

<style lang="less" scoped>
.c-modal-toggle {
    font: inherit;
}

.c-modal-wysiwyg {
    @apply text-14 leading-20;

    /deep/ h2,
    /deep/ h3 {
        @apply font-brandon text-16 font-medium leading-20 tracking-0;
    }

    /deep/ h2 strong,
    /deep/ h3 strong {
        @apply font-medium;
    }
}

@screen md {
    .c-modal-wysiwyg {
        @apply text-14 leading-18;

        /deep/ h2,
        /deep/ h3 {
            @apply font-brandon text-16 font-medium leading-20 tracking-0;
        }

        /deep/ h2 strong,
        /deep/ h3 strong {
            @apply font-medium;
        }
    }
}
</style>
