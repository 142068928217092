<template>
    <div>
        <label v-if="error && errorPlacement === 'above'"
               :for="id"
               class="mb-2 block text-red-500 text-11 md:text-12">
            {{ error }}
        </label>
        <div class="c-checkbox">
            <input
                :id="id"
                ref="input"
                v-validate.disable="constraints"
                v-prohibit-zoom
                class="c-checkbox__input"
                type="checkbox"
                :name="name"
                :value="value"
                :checked="shouldBeChecked"
                v-bind="$attrs"
                @change="updateValue"
                v-on="listeners">
            <label class="c-checkbox__label"
                   :class="{ 'c-checkbox__label--error': error }"
                   :for="id">
                <span
                    class="c-checkbox__pseudo-input u-trans u-trans-bg"
                    :class="[
                        {
                            'c-checkbox__pseudo-input--checked': shouldBeChecked,
                            'c-checkbox__pseudo-input--disabled': showDisabled,
                            'border-red-500': error && (errorPlacement === 'default' || errorPlacement === 'above')
                        },
                        pseudoClass
                    ]">
                    <span
                        class="c-checkbox__checkmark u-trans u-trans-opacity flex items-center xy-center"
                        :class="{ 'text-sand-10': !showDisabled, 'text-white-180': showDisabled }">
                        <c-icon name="checkmark"
                                width="10"
                                height="10"/>
                    </span>
                </span>
                <span :class="labelWrapClass">
                    <span v-if="label"
                          :class="labelClass"
                          class="c-checkbox__label-normal block">
                        {{ label }}
                    </span>
                    <span
                        v-else-if="htmlLabel"
                        v-delegate-html-links
                        class="block"
                        :class="labelClass"
                        v-html="htmlLabel"/>
                    <span
                        v-if="htmlDescription"
                        v-delegate-html-links
                        class="block text-12 font-gibson-light"
                        :class="{ 'mt-4': label || htmlLabel }"
                        v-html="htmlDescription"/>
                    <slot name="htmlDescription"/>
                    <span v-if="error && errorPlacement === 'default'"
                          class="my-5 block text-red-500">
                        {{ error }}
                    </span>
                </span>
            </label>
        </div>
        <label v-if="error && errorPlacement === 'below'"
               :for="id"
               class="mt-16 block text-red-500">
            {{ error }}
        </label>
        <div ref="belowComponent"/>
    </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

/*
Inspired by this: https://www.smashingmagazine.com/2017/08/creating-custom-inputs-vue-js/
 */

@Component({
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    inject: ['$validator']
})
export default class CheckBox extends Vue {
    id: string = '';

    @Prop(String) public label!: string;
    @Prop(String) public labelClass!: string;
    @Prop(String) public pseudoClass!: string;

    @Prop(String) public htmlLabel!: string;

    @Prop({
        type: String
    })
    public htmlDescription!: string;

    @Prop(String) public name!: string;

    @Prop(String) public constraints!: string;

    @Prop({ default: true })
    public value;

    @Prop({ default: true })
    public trueValue;

    @Prop({ default: false })
    public falseValue;

    @Prop({ default: false })
    public modelValue!: boolean | Array<string>;

    @Prop(String) public labelWrapClass!: string;

    @Prop({ default: 'above' })
    public errorPlacement!: 'default' | 'below' | 'above';

    $refs!: {
        input: HTMLInputElement;
    };

    created() {
        this.id = this.name + Math.round(Math.random() * 100000);
    }

    private get field() {
        return this.vvFields[this.name];
    }

    private get error() {
        return this.vvErrors?.first(this.name);
    }

    @Watch('error')
    onErrorChange(error: boolean) {
        const scrollElem = this.$refs['belowComponent'];
        if (error && scrollElem) {
            scrollElem.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }

    private updateValue(event) {
        const isChecked = event.target.checked;

        if (this.modelValue instanceof Array) {
            const newValue: any = [...this.modelValue];

            if (isChecked) {
                newValue.push(this.value);
            } else {
                newValue.splice(newValue.indexOf(this.value), 1);
            }

            this.$emit('change', newValue);
        } else {
            this.$emit('change', isChecked ? this.trueValue : this.falseValue);
        }

        if (this.constraints && this.field.validated && this.field.invalid) {
            this.validate();
        }
    }

    private get shouldBeChecked() {
        if (this.modelValue instanceof Array) {
            return this.modelValue.includes(this.value);
        }
        // Note that `true-value` and `false-value` are camelCase in the JS
        return this.modelValue === this.trueValue;
    }

    private get showDisabled() {
        return !!this.$attrs.disabled;
    }

    private get listeners() {
        // eslint-disable-next-line
        const { change, ...listeners } = this.$listeners;
        return listeners;
    }

    private validate() {
        this.$validator.validate(this.name);
    }

    // From Vue-validate
    private vvErrors!: any;
    private vvFields!: any;
}
</script>
