const TOKEN = '39505e';
// WE use Serendipity font from MyFonts.com, they have a slow counter that loads via CSS import.
// To overcome this slow and render blocking import, we use this hack:
// WebFont URL: https://www.myfonts.com/fonts/nicky-laatz/serendipity/regular/
const $count = document.createElement('script');
const s = document.getElementsByTagName('script');

$count.type = 'text/javascript';
$count.async = !0;
$count.src = '//hello.myfonts.net/count/' + TOKEN;

export default () => {
    if (s && s.length && s[0]) {
        const htmlScriptElement = s[0].parentNode;
        if (htmlScriptElement) htmlScriptElement.insertBefore($count, s[0]);
    }
};
