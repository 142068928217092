<template>
    <Interceptor #default="{ viewed }"
                 :offset="offset"
                 :threshold="0"
                 :tag="tag">
        <template v-if="viewed">
            <slot></slot>
        </template>
    </Interceptor>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Interceptor from '@/project/shared/Interceptor.vue';
@Component({
    components: {
        Interceptor
    }
})
export default class LazyLoaded extends Vue {
    @Prop({ type: Number, required: false, default: 0 })
        offset!: number;

    @Prop({ type: String, default: 'div' }) tag!: string;
}
</script>
